import * as React from 'react'
import Notifications from 'hybrid-ui/layout/components/sidebar/notifications'
import classes from './notification-bell.module.css'
import iconClasses from '../../header-icon.module.css'
import {
  Popover,
  PopoverContent,
  PopoverDescription,
  PopoverTrigger,
  SVG,
} from '@hybr1d-tech/charizard'
import {useGetMetaData, useGetNotifications, useUpdateNotificationsStatus} from 'app/services'
import {utilityKeys} from 'app/services/query-key-factory'
import {useNotificationsStore} from 'app/store/notifications'
import {ICONS} from 'app/utils/constants/icon'
import {
  NotificationsModal,
  flattenNotifications,
} from 'hybrid-ui/layout/components/sidebar/notifications/Notifications'
import {INotification, NOTIFICATION_STATUS} from 'types/notifications'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'

export default function NotificationBell() {
  const [sendResType, setSendResType] = React.useState(false)

  const {isOpen: isPopoverOpen, onClose: onPopoverClose, onOpen: onPopoverOpen} = useDisclosure()
  const {isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen} = useDisclosure()

  const {meta, fetchingMeta} = useGetMetaData(utilityKeys.metaInfoTasks())

  const stringifiedQuery = useNotificationsStore(state => state.stringifiedNotificationsQuery)

  const {notifications, fetchingNotifications, fetchNextPage, response, data} = useGetNotifications(
    stringifiedQuery + (sendResType ? '&res_type=new-past' : ''),
    {enabled: isPopoverOpen},
  )
  const {mutateAsync: updateNotificationsStatusMutate} = useUpdateNotificationsStatus()

  React.useEffect(() => {
    if (!fetchingMeta && Number(meta?.notifications?.total_notifications) > 0) {
      setSendResType(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingMeta])

  const popoverRef = React.useRef<HTMLDivElement>(null)

  const closePopover = () => {
    if (isPopoverOpen) {
      popoverRef.current?.click()
      onPopoverClose()
    }
  }

  const markNotificationsAsSeen = async () => {
    if (data?.pages.length === 1 && isPopoverOpen) {
      const notificationIds = flattenNotifications(notifications)
        .filter((notif: INotification) => notif.status === NOTIFICATION_STATUS.UNSEEN)
        .map((notif: INotification) => notif.id)

      if (notificationIds.length > 0) {
        await updateNotificationsStatusMutate({
          notification_ids: notificationIds,
          status: NOTIFICATION_STATUS.SEEN,
        })
      }
    }
  }
  React.useEffect(() => {
    markNotificationsAsSeen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages.length])

  return (
    <>
      <Popover placement="bottom-end">
        <PopoverTrigger openOnHover={false}>
          <div
            className={classes.bellContainer}
            ref={popoverRef}
            onClick={() => {
              onPopoverOpen()
            }}
          >
            <SVG
              path={ICONS.bell}
              spanClassName={iconClasses.navIconSpan}
              svgClassName={iconClasses.navIcon}
            />

            {(Number(meta?.notifications?.total_notifications) ?? 0) > 0 && (
              <div className={classes.notificationsCount}>
                {meta?.notifications?.total_notifications}
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          bg="white"
          positionerStyles={{
            display: isModalOpen ? 'none' : 'block',
          }}
          className={classes.notifPopoverContent}
        >
          <PopoverDescription>
            <div className={classes.notificationContainer}>
              <Notifications
                // handlePopover={handlePopover}
                onModalOpen={onModalOpen}
                notifications={notifications}
                fetchingNotifications={fetchingNotifications}
                fetchNextPage={fetchNextPage}
                response={response}
              />
            </div>
          </PopoverDescription>
        </PopoverContent>
      </Popover>
      {isModalOpen && (
        <NotificationsModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          onPopoverClose={closePopover}
        />
      )}
    </>
  )
}

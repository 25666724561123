import * as React from 'react'
import TopBarProgress from 'react-topbar-progress-indicator'

// Configure the progress bar
TopBarProgress.config({
  barColors: {
    '0': '#254dda',
  },
  barThickness: 2,
  shadowBlur: 5,
})

// Type for the lazy loading function
type LazyComponent = Promise<{default: React.ComponentType<any>}>
type ImportFactory = () => LazyComponent

// Wrapper component for Suspense
export function SuspensedView({children}: {children: React.ReactNode}) {
  return <React.Suspense fallback={<TopBarProgress />}>{children}</React.Suspense>
}

// Create a lazy loading function with retry logic
function createLazyComponent(factory: ImportFactory, componentName: string) {
  return React.lazy(() => {
    return new Promise<{default: React.ComponentType<any>}>((resolve, reject) => {
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(`hui-retry-${componentName}-refreshed`) || 'false',
      )

      factory()
        .then(component => {
          window.sessionStorage.setItem(`hui-retry-${componentName}-refreshed`, 'false')
          resolve(component)
        })
        .catch(error => {
          if (!hasRefreshed) {
            window.sessionStorage.setItem(`hui-retry-${componentName}-refreshed`, 'true')
            window.location.reload()
            return
          }
          reject(error)
        })
    })
  })
}

// Export the lazy loading utility as an object
export const lazyLoad = {
  component: createLazyComponent,
}

import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'
import classes from './style.module.css'
import {ICONS} from 'app/utils/constants/icon'
import {SVG} from '@hybr1d-tech/charizard'

export default function AddEndNode(props: NodeProps) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{background: '#555'}}
        onConnect={params => {}}
        isConnectable={false}
      />
      <CustomNodeWrapper isSelected={props.selected} style={{padding: '8px 16px 8px 12px'}}>
        <div className={classes.addNewNodeContent}>
          {/* <img className={classes.addImage} src={ICONS.plus} alt="add" /> */}
          <SVG path={ICONS.plus} width={20} height={20} svgClassName={classes.addImage} />
          <p>Add</p>
        </div>
      </CustomNodeWrapper>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{background: '#555'}}
        onConnect={params => {}}
        isConnectable={false}
      />
    </>
  )
}

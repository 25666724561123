import {
  formatISO,
  format,
  isAfter,
  isSameMonth,
  isSameDay,
  differenceInCalendarDays,
  isBefore,
  isWithinInterval,
} from 'date-fns'
import {formatInTimeZone, toDate} from 'date-fns-tz'
import {enUS} from 'date-fns/locale/en-US'

export const convertDisplayDateToISO = (displayDate: string | null): string | undefined => {
  if (!displayDate) return undefined
  const date = toDate(displayDate) // Convert display date to a Date object
  return date.toISOString() // Return the date as an ISO string
}
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
//  used in profile employment section
export const formatDate = (
  isoDateString: string,
  options: Intl.DateTimeFormatOptions = {day: '2-digit', month: 'short', year: 'numeric'},
): string => {
  const date = new Date(isoDateString)
  return new Intl.DateTimeFormat(undefined, options).format(date)
}

export const convertToISODate = (date: Date) => {
  return formatISO(new Date(date))
}

export const formatDateDDMMYYYY = (date?: Date | string) => {
  if (!date) return ''
  return format(new Date(date), 'do MMM yyyy')
}

export const formatDateYYYYMMDD = (
  date: Date | null | undefined,
  initializeWithDateClass = true,
) => {
  if (date) {
    const d = format(initializeWithDateClass ? new Date(date) : date, 'yyyy-MM-dd')
    return d
  }
  return ''
}

export const isDateFutureDate = (date: Date) => {
  return isAfter(new Date(date), new Date())
}

export const getDay = (date: string): string => {
  return format(new Date(date), 'EEEE')
}

export const formatDateDoMMMYYYY = (date: string) => {
  return format(new Date(date), 'do MMM yyyy')
}

export const isDateToday = (date: string) => {
  return format(new Date(), 'yyyy-MM-dd') === date
}

export const formatDateDDMMYYY = (date = new Date()) => {
  return format(date, 'dd/MM/yyyy')
}

export const formatDateRange = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate

  if (isSameDay(start, end)) {
    return format(start, 'MMMM dd')
  } else if (isSameMonth(start, end)) {
    return `${format(start, 'MMMM dd')} - ${format(end, 'dd')}`
  } else {
    return `${format(start, 'MMMM dd')} - ${format(end, 'MMMM dd')}`
  }
}

export const getDifferenceInDays = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate

  const dayDifference = isSameDay(start, end) ? 1 : differenceInCalendarDays(end, start) + 1
  return `${dayDifference} ${dayDifference === 1 ? 'day' : 'days'}`
}

export const getDifference = (startDate: Date, endDate: Date): number => {
  const start = startDate
  const end = endDate

  const dayDifference = isSameDay(start, end) ? 1 : differenceInCalendarDays(end, start) + 1
  return dayDifference
}

export const getDateRangeStatus = (startDate: Date, endDate: Date): string => {
  const start = startDate
  const end = endDate
  const now = new Date()

  if (isBefore(end, now) && !isSameDay(end, now)) {
    return 'past'
  } else if (isWithinInterval(now, {start, end}) || isSameDay(now, start) || isSameDay(now, end)) {
    return 'ongoing'
  } else if (isAfter(start, now)) {
    return 'upcoming'
  } else {
    return ''
  }
}

export const getPastDate = (days: number = 0) => {
  const today = new Date()
  const pastDate = new Date()
  const timestamp = pastDate.setDate(today.getDate() - days)
  return formatDateYYYYMMDD(new Date(timestamp))
}

export const getFutureDate = (days: number = 0) => {
  const today = new Date()
  const futureDate = new Date()
  const timestamp = futureDate.setDate(today.getDate() + days)
  return formatDateYYYYMMDD(new Date(timestamp))
}

export const getPastThirtyDaysRange = () => {
  const today = new Date()
  const endDate = new Date()
  return {
    start: new Date(today.setDate(endDate.getDate() - 30)),
    end: endDate,
  }
}

export const isISODateString = (str?: string | null) => {
  if (!str) {
    return false
  }
  const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  return isoRegex.test(str)
}

//  ! NEW DATE HELPERS DONT USE OLD ONES

export function formatUTCDateToLocal(utcDate) {
  const options = Intl.DateTimeFormat().resolvedOptions()
  const {timeZone} = options

  // Convert the UTC date to the local time zone
  const formattedDate = formatInTimeZone(utcDate, timeZone, 'dd MMM yyyy', {locale: enUS})

  return formattedDate
}

export const getTimeFromTimestamp = (timestamp: string) => {
  const date = new Date(timestamp)
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const period = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes
  return `${hours}:${formattedMinutes} ${period}`
}

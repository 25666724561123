import {QueryKey, useQuery} from '@tanstack/react-query'
import {publicHolidayKeys, timeAwayV2Keys} from '../query-key-factory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {MANAGE_TIME_AWAY} from 'app/utils/constants/toast'
import {TimeAwayServiceV2} from './time-away.service'
import {LeaveActivitiesQueryParams, PublicHolidayCalendarApplicableTo} from 'types/time-away'
import {Condition} from 'app/modules/workflow/components/drawer-form/utils/types'
import {
  PublicHolidayCalendarQueries,
  PublicHolidayQueries,
  SourceCalendarQueries,
} from 'app/store/time-away'

const svc = new TimeAwayServiceV2()

export const useGetTimeAwayDetails = (userId: string) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.details(userId),
    queryFn: () => svc.getTimeAwayDetails(userId),
  })

  return {
    details: res.data,
    fetchingDetails: res.isPending,
    isErrorDetails: res.isError,
  }
}
export const useGetLeaveActivities = (
  userId: string,
  leaveActivityPeriod: LeaveActivitiesQueryParams,
) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.leaveActivities(userId, leaveActivityPeriod),
    queryFn: () => svc.getLeaveActivities(userId, leaveActivityPeriod),
  })

  return {
    activities: res.data,
    fetchingActivities: res.isPending,
    isErrorActivities: res.isError,
  }
}
export const useGetLeaveConfig = (userId: string) => {
  const res = useQuery({
    queryKey: timeAwayV2Keys.leaveConfig(userId),
    queryFn: () => svc.getLeaveConfig(userId),
  })

  return {
    configs: res.data,
    fetchingConfigs: res.isPending,
    isErrorConfigs: res.isError,
  }
}
export const useGetLeaveBalance = () => {
  const res = useMutate(svc.getLeaveBalance)
  return {
    getLeaveBalance: res.mutateAsync,
    gettingLeaveBalance: res.isPending,
  }
}

export const useCreateLeaveRequests = () => {
  const res = useMutate(svc.createLeaveRequests, timeAwayV2Keys.all, MANAGE_TIME_AWAY.bookTime)
  return {
    createRequest: res.mutateAsync,
    isCreatingRequest: res.isPending,
  }
}

export const useDeleteCalendar = (queryIdToInvalidate: QueryKey, successMsg: string) => {
  const res = useMutate(svc.deleteCalendar, queryIdToInvalidate, successMsg)

  return {
    mutateAsync: res.mutateAsync,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useDeleteHoliday = (queryIdToInvalidate: QueryKey, successMsg: string) => {
  const res = useMutate(svc.deleteHoliday, queryIdToInvalidate, successMsg)

  return {
    mutateAsync: res.mutateAsync,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetPublicHolidaysCalendars = (query: PublicHolidayCalendarQueries) => {
  const res = useQuery({
    queryKey: publicHolidayKeys.publicHolidaysCalendars(query),
    queryFn: () => svc.getPublicHolidayCalendars(query),
  })

  return {
    calendars: res.data?.data,
    isPendingPH: res.isPending,
    isErrorPH: res.isError,
    metaData: res.data?.meta_data,
  }
}

export const useGetAllSourceCalendars = (query?: SourceCalendarQueries) => {
  const res = useQuery({
    queryKey: publicHolidayKeys.allSourceCalendars(query),
    queryFn: () => svc.getAllCalendars(query),
  })

  return {
    calendars: res.data?.data,
    isPendingPH: res.isPending,
    isErrorPH: res.isError,
    metaData: res.data?.meta_data,
  }
}

export const useGetEmployeesByPHId = (id: string | undefined) => {
  const res = useQuery({
    queryKey: publicHolidayKeys.employeesByPHId(id),
    queryFn: () => {
      if (id) return svc.getEmployeesByPHId(id)
    },
  })

  return {
    employees: res.data?.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useAddCustomCalendarV2 = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addCustomCalendar, queryIdToInvalidate, successMsg)
}

export const useAddStandardCalendarV2 = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addStandardCalendar, queryIdToInvalidate, successMsg)
}

export const useAddNewHoliday = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.addNewHoliday, queryIdToInvalidate, successMsg)
}

export const useEditHoliday = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  return useMutate(svc.editHoliday, queryIdToInvalidate, successMsg)
}

export const useGetPublicHolidaysV2 = (
  publicHolidayCalendarId: string,
  query: PublicHolidayQueries,
) => {
  const res = useQuery({
    queryKey: publicHolidayKeys.publicHolidays(publicHolidayCalendarId, query),
    queryFn: () => svc.getPublicHolidays(publicHolidayCalendarId, query),
    enabled: !!publicHolidayCalendarId,
  })

  return {
    calendarName: res?.data?.data.calendar_name ?? '',
    publicHolidays: res?.data?.data.holidays ?? [],
    publicHolidayCount: res?.data?.data.holidays?.length ?? 0,
    fetchingHolidays: res.isLoading,
    isFetchingHolidaysError: res?.isError,
    metaData: res.data?.meta_data,
  }
}

export const useGetManageEmployeesConditionsPH = () => {
  const res = useQuery({
    queryKey: publicHolidayKeys.manageEmpConditions(),
    queryFn: svc.getManageEmployeeConditionsList,
  })
  return {
    conditions: res.data?.data,
    isConditionsPending: res.isPending,
    isConditionsError: res.isError,
  }
}

export const useGetPHManageEmployeesList = (
  applicableTo: PublicHolidayCalendarApplicableTo,
  conditions: Condition[][],
) => {
  let conditionLength = conditions?.length
  const res = useQuery({
    queryKey: [...publicHolidayKeys.manageEmpList(conditions), conditions],
    queryFn: () => {
      if (conditions && !!conditions[conditionLength - 1][0].value.length) {
        return svc.getPHManageEmployeesList(conditions)
      }
    },
    enabled: !!applicableTo,
  })

  return {
    employeesData: res.data?.data,
    isListPending: res.isPending,
    isListError: res.isError,
  }
}

export const useCalendarConfig = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  const res = useMutate(svc.editCalendarConfig, queryIdToInvalidate, successMsg)
  return {
    mutateAsync: res.mutateAsync,
    isCalendarConfigPending: res.isPending,
  }
}

export const useDuplicateCalendar = (queryIdToInvalidate?: QueryKey, successMsg?: string) => {
  const res = useMutate(svc.duplicateCalendar, queryIdToInvalidate, successMsg)
  return {
    mutateAsync: res.mutateAsync,
    isDuplicateCalendarPending: res.isPending,
  }
}

import * as React from 'react'
import clsx from 'clsx'
import classes from './styles.module.css'
import {SVG} from '@hybr1d-tech/charizard'
import {ICONS} from 'app/utils/constants/icon'

const TESTIMONIAL_CHANGE_INTERVAL = 8000

interface AuthWrapperProps {
  children: React.ReactNode /* children to render */
  hideLeftContainer?: boolean /* to hide left container or not */
  isSignUp?: boolean /* to show sign up page or not */
}

export default function AuthWrapper({children, hideLeftContainer, isSignUp}: AuthWrapperProps) {
  const [testimonialIdx, setTestimonialIdx] = React.useState(0)

  const profilePictures = TESTIMONIALS.map(t => t.profilePic)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTestimonialIdx(prevIdx => {
        if (prevIdx === TESTIMONIALS.length - 1) {
          return 0
        }
        return prevIdx + 1
      })
    }, TESTIMONIAL_CHANGE_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className={classes.authWrapper}>
      <div className={classes.gradientStrip}></div>
      <div className={classes.topThread}>
        <img src="/modules/auth/threads-1.svg" alt="top thread" />
      </div>
      <div className={classes.bottomThread}>
        <img src="/modules/auth/threads-2.svg" alt="bottom thread" />
      </div>
      <div className={classes.authContentContainer}>
        {!hideLeftContainer && (
          <div className={clsx(classes.leftWrapper, {[classes.leftWrapperSignup]: isSignUp})}>
            <div className={clsx(classes.leftContainer)}>
              {isSignUp ? (
                <div className={classes.signUpInfoContainer}>
                  <p className={classes.header}>
                    Sign up with ZenAdmin's unified platform now and get $5000 worth of free device
                    procurement services.
                  </p>
                  <p>
                    Are you tired of the hassles involved in finding vendors in different countries,
                    negotiating on costs, and dealing with delivery timelines and logistical issues?
                  </p>
                  <p>
                    ZenAdmin's device procurement platform can help you Onboard new hires with ease
                    and procure devices in seconds.
                  </p>
                  <ul>
                    <li>Hassle-free device procurement with unified dashboard</li>
                    <li>
                      Price match guarantee on devices from more than 10+ brands including Apple,
                      Microsoft, Lenovo, Dell, HP, and more
                    </li>
                    <li>Remote installation of required software and apps</li>
                    <li>Last mile delivery with 99% on-time rate to over 120+ countries.</li>
                  </ul>
                  <p>Last mile delivery with 99% on-time rate to over 120+ countries</p>
                  <img alt="Logos" src={ICONS.signupBrandLogos} className={classes.brandLogos} />
                </div>
              ) : (
                <div className={classes.testimonialContainer}>
                  <p className={classes.message}>{`“${TESTIMONIALS[testimonialIdx].message}`}</p>
                  <h6 className={classes.name}>{TESTIMONIALS[testimonialIdx].name}</h6>
                  <p className={classes.position}>{TESTIMONIALS[testimonialIdx].position}</p>
                  <div className={classes.profilePictures}>
                    {profilePictures.map((pic, idx) => (
                      <div key={idx} className={classes.picContainer}>
                        {testimonialIdx === idx && (
                          <SVG
                            path="/modules/auth/spinner.svg"
                            spanClassName={classes.spinner}
                            svgClassName={classes.spinnerSvg}
                          />
                        )}
                        <img src={pic} alt="testimonial user" className={classes.profilePic} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={clsx(hideLeftContainer && 'vw-100', classes.rightWrapper)}>
          <div className={classes.hybridLogoContainer}>
            <img alt="ZenAdmin Logo" src={ICONS.logoFull} width="170" />
          </div>
          <div className={clsx(classes.formContainer, isSignUp && classes.signUpFormContainer)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const TESTIMONIALS = [
  {
    message:
      'ZenAdmin really removes the complexity from our side, whilst upholding a high standard of employee onboarding and offboarding',
    name: 'John Esguerra',
    position: 'Business IT Manager',
    company: 'Carousell',
    profilePic: '/modules/auth/john-carousell.png',
  },
  {
    message:
      "From the moment we started using ZenAdmin, we knew we had made the right choice. The platform's intuitive interface has made it easy for us to procure and keep a track on our device inventory. Before ZenAdmin we would often find ourselves dealing with multiple vendors and struggling with managing our device inventory. However, ZenAdmin has made it possible for us to empower our global workforce with everything they need to become productive from day 1.",
    name: 'Robert Daniel Cook',
    position: 'IT Manager',
    company: 'beam',
    profilePic: '/modules/auth/robert-beam.png',
  },
  {
    message:
      "Before working with ZenAdmin we had trouble equipping our team members globally in more than 45 countries but now it's all reduced to a simple few clicks process. With the advent and rise of remote working, ZenAdmin will be a game changer for forward-looking companies looking for an employee management platform for their global workforce.",
    name: 'Siva Surya',
    position: 'Resource Coordinator',
    company: 'Multiplier',
    profilePic: '/modules/auth/siva-multiplier.png',
  },
]

import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Edge, Handle, Node, NodeProps, Position} from 'reactflow'
import classes from './style.module.css'
import {useCreateWorkflowStore} from 'app/store/workflow'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import DeleteNodeModal from '../../../all-modals/delete-node-modal'
import {
  getAllOutgoers,
  isConditionalEdge,
  isMiddleNodeBottomHandleConnectable,
  isMiddleNodeTopHandleConnectable,
} from 'app/utils/helper/create-workflow'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'
import {ICONS} from 'app/utils/constants/icon'

export default function MiddleNode(props: NodeProps) {
  const {
    data: {nodeContent},
  } = props

  const {isOpen, onClose, onOpen} = useDisclosure()
  const [childEdgeSelected, setChildEdgeSelected] = React.useState(false)
  const deleteNode = useCreateWorkflowStore(state => state.deleteNode)
  const handleDrawerState = useCreateWorkflowStore(state => state.handleDrawerState)
  const nodes = useCreateWorkflowStore(state => state.nodes)
  const edges = useCreateWorkflowStore(state => state.edges)
  const selectedEdge = useCreateWorkflowStore(state => state.selectedEdge)

  const handleSelect = (type: DRAWER_CONTENT_TYPE, icon?: string) => {
    handleDrawerState(type, true, props.id, false, icon)
  }

  const handleNodeDelete = () => {
    deleteNode(props.id)
    onClose()
  }

  React.useEffect(() => {
    if (
      selectedEdge &&
      selectedEdge?.source === props.id &&
      !isConditionalEdge(selectedEdge as unknown as Edge)
    ) {
      setChildEdgeSelected(true)
    } else if (selectedEdge && selectedEdge?.source !== props.id) {
      setChildEdgeSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEdge?.id])

  return (
    <div>
      <div className={classes.container} onClick={() => handleSelect(props.data.drawerContent)}>
        <Handle
          type="target"
          position={Position.Top}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={isMiddleNodeTopHandleConnectable(nodes, edges, props.id)}
        />
        <CustomNodeWrapper isSelected={props.selected}>
          <div className={classes.nodeContent}>
            <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
            <div>
              <p className={classes.title}>{nodeContent?.title}</p>
              <p className={classes.subTitle}>{nodeContent?.subTitle}</p>
            </div>
          </div>
        </CustomNodeWrapper>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background:
              childEdgeSelected && getAllOutgoers({id: props.id} as Node, nodes, edges).length > 2
                ? '#254dda'
                : '#555',
          }}
          onConnect={params => {}}
          isConnectable={isMiddleNodeBottomHandleConnectable(nodes, edges, props.id)}
        />

        {/* {endNodes?.includes(props.id) && ( */}
        <div className={classes.imageContainer}>
          <DeleteNodeModal
            bodyTitle={'Deleting this node will disconnect the subsequent steps of the workflow.'}
            isOpen={isOpen}
            onClose={onClose}
            onOk={handleNodeDelete}
          >
            <img
              src={ICONS.deleteBin}
              onClick={e => {
                e.stopPropagation()
                onOpen()
              }}
              alt={'delete'}
              className={classes.deleteIcon}
              title="Delete node"
            />
          </DeleteNodeModal>
        </div>
        {/* )} */}
      </div>
    </div>
  )
}

import classes from './header.module.css'
import {BUTTON_TYPE, BUTTON_VARIANT, Button, SVG} from '@hybr1d-tech/charizard'
import {useDisclosure} from 'hybrid-ui/hooks/use-disclosure'
import {MenuInner} from './MenuInner'
import {ICONS} from 'app/utils/constants/icon'

export default function MobileBar() {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <div className={classes.mobileBar} title="Show navigation menu" onClick={onOpen}>
        <Button
          type={BUTTON_TYPE.ICON_ONLY}
          variant={BUTTON_VARIANT.TERTIARY}
          icon={
            <SVG
              path={ICONS.menu}
              svgClassName={classes.menuIcon}
              spanClassName={classes.menuIconSpan}
            />
          }
        ></Button>
      </div>
      <MenuInner isOpen={isOpen} onClose={onClose} />
    </>
  )
}

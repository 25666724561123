export const ENV = import.meta.env.MODE
export const isProduction = ENV === 'production'
export const isStaging = ENV === 'staging'
export const isDev = ENV === 'development' || (!isProduction && !isStaging)
export const ZENADMIN_APP_VERSION = import.meta.env.VITE_VERSION

export const BRAND_NAME = 'ZenAdmin'
export const BRAND_SHORT_NAME = 'ZAP'

const __TWEAK_HYBR1D_API_ENDPOINT__ = '__TWEAK_HYBR1D_API_ENDPOINT__'

export const API_END_POINT =
  !isProduction && localStorage.getItem(__TWEAK_HYBR1D_API_ENDPOINT__)
    ? localStorage.getItem(__TWEAK_HYBR1D_API_ENDPOINT__)
    : import.meta.env.VITE_API_END_POINT

export const LOCAL_API_END_POINT = 'http://localhost:4000/api'

export const SENTRY_DSN = import.meta.env.VITE_SENTRY as string

export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL as string

export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID as string

export const VITE_MICROSOFT_APP_ID = import.meta.env.VITE_MICROSOFT_APP_ID as string
export const MS_REDIRECT_URL = isProduction
  ? import.meta.env.VITE_MS_END_POINT_PROD
  : isStaging
    ? import.meta.env.VITE_MS_END_POINT_STAGING
    : isDev
      ? import.meta.env.VITE_MS_END_POINT_DEV
      : import.meta.env.VITE_MS_END_POINT_LOCAL

export const BROWSER_ID = 'browser-id'
export const IS_BRAVE = 'is-brave'
export const REF_KEY = 'ref-key'
export const EMAIL_ENCRYPTION_KEY = import.meta.env.VITE_EMAIL_ENCRYPT_KEY as string
export const CHROME_EXTENSION_ID = import.meta.env.VITE_CHROME_EXTENSION_ID
export const DEV_ORIGIN = 'https://internal.usehybrid.co'
export const STAGING_ORIGIN = 'https://console.usehybrid.co'
export const PROD_ORIGIN = 'https://console.zenadmin.ai'
export const APP_ORIGIN = isDev ? DEV_ORIGIN : isStaging ? STAGING_ORIGIN : PROD_ORIGIN

// zenex
export const ZENEX_DEV_ORIGIN = 'https://user-dev.usehybrid.co'
export const ZENEX_STAGING_ORIGIN = 'https://user.usehybrid.co'
export const ZENEX_PROD_ORIGIN = 'https://user.zenadmin.ai'
export const ZENEX_APP_ORIGIN = isDev
  ? ZENEX_DEV_ORIGIN
  : isStaging
    ? ZENEX_STAGING_ORIGIN
    : ZENEX_PROD_ORIGIN

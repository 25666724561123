import * as React from 'react'
import clsx from 'clsx'
import classes from './styles.module.css'
import {Link, useLocation} from 'react-router'
import {Pill, SVG, TooltipV2} from '@hybr1d-tech/charizard'
import {useSidebarMinimized, useToggleMini} from 'app/store/layout'
import {utilityKeys} from 'app/services/query-key-factory'
import {useGetMetaData} from 'app/services'
import type {MenuItem} from '../types'

interface SidebarMenuItemProps {
  item: MenuItem
  isMobile?: boolean
}

const MenuTitle = React.memo(function MenuTitle({
  children,
  className,
  isTasks,
}: {
  children: React.ReactNode
  className: string
  isTasks: boolean
}) {
  if (isTasks) return <TaskMenuTitle className={className}>{children}</TaskMenuTitle>
  return <span className={clsx(className, classes.titleAnimation)}>{children}</span>
})

const TaskMenuTitle = ({className, children}) => {
  const {meta} = useGetMetaData(utilityKeys.metaInfoTasks())
  return (
    <span className={clsx(className, classes.titleAnimation)}>
      {children}{' '}
      {meta?.tasks && +meta?.tasks.total_tasks > 0 ? (
        <TooltipV2
          placement="right"
          trigger={<Pill>{meta?.tasks.total_tasks}</Pill>}
          content={<div>Pending Tasks</div>}
        />
      ) : null}
    </span>
  )
}

const MenuContent = React.memo(function MenuContent({
  item,
  isActive,
  isMini,
  isMobile,
  onClick,
}: {
  item: MenuItem
  isActive: boolean
  isMini: boolean
  isMobile: boolean
  onClick: () => void
}) {
  const defaultIconClasses = React.useMemo(
    () => clsx(classes.icon, classes.defaultIcon, isActive && classes.iconHidden),
    [isActive],
  )

  const activeIconClasses = React.useMemo(
    () => clsx(classes.icon, classes.activeIcon, !isActive && classes.iconHidden),
    [isActive],
  )

  const contentClasses = React.useMemo(
    () =>
      clsx(
        classes.menuContent,
        isActive && classes.menuContentActive,
        isMini && !isMobile && classes.menuContentMini,
      ),
    [isActive, isMini, isMobile],
  )

  const titleClasses = React.useMemo(
    () => clsx('zap-content-medium', classes.itemTitle, isActive && classes.itemTitleActive),
    [isActive],
  )

  const isTasks = item.key === 'tasks_approvals'

  return (
    <div className={classes.menuItem} onClick={onClick}>
      <Link to={item.route} className={classes.menuLink}>
        <div className={contentClasses}>
          <div className={classes.iconContainer}>
            <SVG path={item.icon.default} svgClassName={defaultIconClasses} />
            <SVG path={item.icon.active} svgClassName={activeIconClasses} />
          </div>

          {(!isMini || isMobile) && (
            <MenuTitle className={titleClasses} isTasks={isTasks}>
              {item.name}
            </MenuTitle>
          )}
        </div>
      </Link>
    </div>
  )
})

export default React.memo(function SidebarMenuItem({item, isMobile = false}: SidebarMenuItemProps) {
  const {pathname} = useLocation()
  const isActive = React.useMemo(
    () => checkIsActive(pathname, item.rootRoute),
    [pathname, item.rootRoute],
  )
  const toggleMini = useToggleMini()
  const isMini = useSidebarMinimized()

  const handleClick = React.useCallback(() => {
    if (!isMini) {
      requestAnimationFrame(() => {
        toggleMini()
      })
    }
  }, [isMini, toggleMini])

  const menuContent = React.useMemo(
    () => (
      <MenuContent
        item={item}
        isActive={isActive}
        isMini={isMini}
        isMobile={isMobile}
        onClick={handleClick}
      />
    ),
    [item, isActive, isMini, isMobile, handleClick],
  )

  if (isMini && !isMobile) {
    return <TooltipV2 placement="right" opacity={1} trigger={menuContent} content={item.name} />
  }

  return menuContent
})

function checkIsActive(pathname: string, route: string) {
  // Split into segments and filter out empty strings
  const pathSegments = pathname.split('/').filter(Boolean)
  const routeSegments = route.split('/').filter(Boolean)

  // Match by first N segments where N is the length of route segments
  return routeSegments.every((segment, index) => pathSegments[index] === segment)
}

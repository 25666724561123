import {OrderAssignedTo} from './order'
import {ISoftwareOwner} from './software'
import {IAssignee} from './tasks'
import {ITeamMemberDetails} from './team'

export type UserQueryState = {
  search: string
  filter: {
    tag: string | null
    countryId: string | null
    startDate: string | null
  }
  page: number
  limit: number
  sortOrder: 'asc' | 'desc'
}

export interface AddressesEntity {
  id: string
  label?: null
  address_line1: string
  address_line2?: string | null
  city: string
  state?: string | null
  postal_code: string
  recipient_name?: string
  unit_number?: string | null
  user_id: string
  created_at: string
  updated_at: string
  country_id: string
}
export interface UserTagsEntity {
  tag_id: string
  tag: string[]
}

export interface ReportingManager {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  email: string
  work_email: string
}
export interface Country {
  id: string
  name: string
  dial_code: number
  country_code: string
}
export interface AllocatedInventoriesEntity {
  id: string
  serial_number: string
  product_variant: ProductVariant
}
export interface ProductVariant {
  id: string
  article_number: string
  color: string
  configuration: string
  country_id: string
  purchase_price: number
  retail_price?: number | null
  subscription_price?: number | null
  variant_specification?: string | null
  product: Product
  country: Country1
}
export interface Product {
  id: string
  title: string
  product_company: Info
  product_type: Info
  description?: string | null
}
export interface Info {
  id: string
  name: string
}
export interface Country1 {
  id: string
  name: string
  currency: {
    name: string
  }
}

export enum USER_STATUS {
  NEW = 'new',
  ACTIVE = 'active',
  OFFBOARDING = 'offboarding',
  OFFBOARDED = 'offboarded',
  DELETED = 'deleted',
  INCOGNITO = 'incognito',
}

export enum ONBOARDING_TYPES {
  SELF_ONBOARDING = 'self_onboarding',
}

export interface FullName {
  first_name: string
  last_name?: string
  middle_name?: string
  [key: string]: any
}

export type AllUserTypeUnion =
  | ITeamMemberDetails
  | IAssignee
  | ISoftwareOwner
  | FullName
  | OrderAssignedTo

import {useInfiniteQuery, useQuery} from '@tanstack/react-query'
import {
  WorkflowGoogleService,
  WorkflowMicrosoftService,
  WorkflowsService,
} from './workflows.service'
import {saasProvisionAppsKeys, workflowKeys} from '../query-key-factory'
import {useMutate} from 'app/utils/hooks/useMutate'
import {WORKFLOWS_TOASTS} from 'app/utils/constants/toast'
import {WorkflowQueries, WorkflowTriggerQueries} from 'app/store/workflow'
import {PERSONALIZE_VARIABLE_TYPE, SaasProvisionAppQuery} from 'types/workflows'
import {format} from 'date-fns'
import {getActionMeta} from 'app/modules/workflow/utils/helpers'
import {DEFAULT_PAGE} from '@hybr1d-tech/charizard'

const svc = new WorkflowsService()
const googleSvc = new WorkflowGoogleService()
const msSvc = new WorkflowMicrosoftService()

export const useGetWorkflows = (query: WorkflowQueries) => {
  const res = useQuery({
    queryKey: workflowKeys.list(query),
    queryFn: () => svc.getWorkflows(query),
  })

  return {
    workflows: res.data?.data ?? [],
    fetchingWorkflows: res.isPending,
    errorFetchingWorkflows: res.isError,
    metadata: res?.data?.meta_data,
  }
}

export const useGetWorkflow = (workflowId: string) => {
  const res = useQuery({
    queryKey: workflowKeys.detail(workflowId),
    queryFn: () => svc.getWorkflow(workflowId),
    enabled: !!workflowId,
  })

  return {
    workflow: res.data,
    fetchingWorkflow: res.isPending,
    errorFetchingWorkflow: res.isError,
  }
}

export const useGetWorkflowStats = (workflowId: string) => {
  const res = useQuery({
    queryKey: workflowKeys.stats(workflowId),
    queryFn: () => svc.getWorkflowStats(workflowId),
    enabled: !!workflowId,
  })

  return {
    stats: res.data,
    fetchingStats: res.isPending,
    errorFetchingStats: res.isError,
  }
}

export const useGetWorkflowTriggerHistory = (query: WorkflowTriggerQueries, workflowId: string) => {
  const res = useQuery({
    queryKey: workflowKeys.triggerHistory(workflowId, query),
    queryFn: () => svc.getWorkflowTriggerHistory(query, workflowId),
  })

  return {
    triggerHistory: res.data?.data ?? [],
    fetchingTriggerHistory: res.isFetching,
    errorFetchingTriggerHistory: res.isError,
    metadata: res?.data?.meta_data,
    refetch: res.refetch,
  }
}

export const useGetWorkflowTriggerHistoryFilters = (workflowId: string) => {
  const res = useQuery({
    queryKey: workflowKeys.triggerHistoryFilters(),
    queryFn: () => svc.getWorkflowTriggerHistoryFilters(workflowId),
  })

  return {
    filters: res.data || [],
    fetchingFilters: res.isPending,
    isErrorFilters: res.isError,
  }
}

export const useGetWorkflowAuditHistory = (query: any, workflowId: string) => {
  const res = useInfiniteQuery({
    queryKey: workflowKeys.activity(workflowId, query),
    queryFn: ({pageParam = 0}) => svc.getWorkflowAuditHistory(query, pageParam, workflowId),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.data) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
    initialPageParam: 0,
    enabled: !!workflowId,
  })

  const totalActivity = res?.data?.pages?.flatMap(page => page.data)

  const activities = totalActivity?.map(activity => ({
    ...activity,
    ...getActionMeta(activity.action, activity.type, activity.node_type, activity.status),
    content: activity.text,
    date: activity?.date && format(new Date(activity.date), "do MMM yyyy '  ' h:mm a"),
  }))

  return {
    activities: activities ?? [],
    isLoading: res.isLoading,
    isFetching: res.isFetching,
    fetchNextPage: res.fetchNextPage,
    metadata: res?.data?.pages?.[0]?.meta_data,
  }
}

export const useGetWorkflowFilters = () => {
  const res = useQuery({
    queryKey: workflowKeys.listFilters(),
    queryFn: svc.getWorkflowFilters,
  })

  return {
    filters: res.data || [],
    fetchingFilters: res.isPending,
    isErrorFilters: res.isError,
  }
}

export const useGetWorkflowCategory = () => {
  const res = useQuery({queryKey: workflowKeys.category(), queryFn: svc.getWorkflowCategory})

  const categories = (res?.data || []).map(category => ({
    label: category.name,
    value: category.id,
  }))

  return {
    categories,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useCreateWorkflow = () => {
  return useMutate(svc.createWorkflow, workflowKeys.list(), WORKFLOWS_TOASTS.createWorkflow, {
    hideToast: true,
  })
}

export const useUpdateWorkflow = (showNotification?: boolean, manual?: boolean) => {
  return useMutate(
    svc.updateWorkflow,
    workflowKeys.all,
    showNotification ? WORKFLOWS_TOASTS.updateWorkflow : undefined,
    undefined,
    {refetchType: 'all'},
  )
}

export const useDuplicateWorkflow = () => {
  return useMutate(svc.duplicateWorkflow, workflowKeys.all, WORKFLOWS_TOASTS.duplicateWorkflow)
}

export const useDeleteWorkflow = () => {
  return useMutate(
    svc.deleteWorkflow,
    workflowKeys.lists(),
    WORKFLOWS_TOASTS.deleteWorkflow,
    undefined,
    {
      refetchType: 'all',
    },
  )
}

export const useStopWorkflowTriggerExecution = () => {
  return useMutate(
    svc.stopWorkflowTriggerExecution,
    workflowKeys.details(),
    WORKFLOWS_TOASTS.stopWorkflowTriggerExecution,
  )
}

export const useWorkflowAction = () => {
  return useMutate(
    svc.workflowAction,
    workflowKeys.all,
    WORKFLOWS_TOASTS.workflowAction,
    undefined,
    {
      refetchType: 'all',
    },
  )
}

export const useGetWorkflowNodeOptions = () => {
  const res = useQuery({queryKey: workflowKeys.nodeOptions(), queryFn: svc.getNodeOptions})

  return {
    options: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useWorkflowVariable = (variables: PERSONALIZE_VARIABLE_TYPE[]) => {
  const res = useQuery({
    queryKey: workflowKeys.companyPersonalizedVariable(),
    queryFn: () => svc.getWorkflowVariables(variables),
  })
  return {
    options: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useEmailRecipient = (search?: string) => {
  const res = useQuery({
    queryKey: workflowKeys.emailRecipient(search),
    queryFn: () => svc.getEmailRecipient(search),
  })
  return {
    options: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetGoogleProvisioningDetails = () => {
  const res = useQuery({
    queryKey: workflowKeys.googleProvisioning(),
    queryFn: svc.getGoogleProvisioningDetails,
  })
  return {
    data: res.data,
    isPending: res.isPending,
    isError: res.isError,
    refetch: res.refetch,
  }
}

export const useGetMSProvisioningDetails = () => {
  const res = useQuery({
    queryKey: workflowKeys.msProvisioning(),
    queryFn: msSvc.getProvDetails,
  })
  return {
    data: res.data,
    isPending: res.isPending,
    isError: res.isError,
    refetch: res.refetch,
  }
}

export const useUpdateNode = () => {
  return useMutate(svc.updateNode, workflowKeys.all, WORKFLOWS_TOASTS.workflowNodeUpdate)
}

export const useGetWorkflowDetailsV2 = (id: string) => {
  const res = useQuery({
    queryKey: workflowKeys.detail(id),
    queryFn: () => svc.getWorkflowDetails(id),
  })
  return {
    data: res.data,
    isPending: res.isPending,
    isError: res.isError,
    isFetching: res.isFetching,
  }
}

export const useGetWorkflowExecutionDetailsV2 = (id: string) => {
  const res = useQuery({
    queryKey: workflowKeys.executionDetails(id),
    queryFn: () => svc.getWorkflowExecutionData(id),
  })
  return {
    data: res.data,
    isPending: res.isPending || res.isFetching,
    isError: res.isError,
  }
}

export const useGetConditionFields = (id: string) => {
  const res = useQuery({
    queryKey: workflowKeys.conditionFields(),
    queryFn: () => svc.getConditionFields(id),
  })
  return {
    fields: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetGoogleGroups = (id: string, status?: boolean, search?: string) => {
  const res = useQuery({
    queryKey: workflowKeys.googleGroups(id),
    queryFn: () => googleSvc.getGoogleGroupsAlt(id, search),
    enabled: !!status,
  })
  return {
    groups: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetMSGroups = (id: string, status?: boolean, search?: string) => {
  const res = useQuery({
    queryKey: workflowKeys.msGroups(id),
    queryFn: () => msSvc.getMicrosoftGroupsAlt(id, search),
    enabled: !!status,
  })
  return {
    groups: res.data,
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useGetSAASProvisioningApps = (queries: SaasProvisionAppQuery) => {
  const response = useInfiniteQuery({
    queryKey: saasProvisionAppsKeys.listByQuery(queries),
    queryFn: ({pageParam}) => svc.getAppsForProvisioning({...queries, page: pageParam}),
    initialPageParam: DEFAULT_PAGE,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.data?.length) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
  })

  const appOptions = response?.data?.pages?.flatMap(page => page.data) ?? []
  const metaData = response?.data?.pages[0]?.meta_data
  return {
    ...response,
    appOptions,
    metaData,
    isLoadingOptions: response.isLoading,
    isFetchingOptions: response.isFetching,
    isOptionsPending: response.isPending,
    isOptionsError: response.isError,
    fetchMoreOptions: response.fetchNextPage,
    hasMoreOptions: response.hasNextPage,
  }
}

export const useGetSAASDeProvisioningApps = (queries: SaasProvisionAppQuery) => {
  const response = useInfiniteQuery({
    queryKey: saasProvisionAppsKeys.listByQuery(queries),
    queryFn: ({pageParam}) => svc.getAppsForDeProvisioning({...queries, page: pageParam}),
    initialPageParam: DEFAULT_PAGE,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.data?.length) {
        return lastPage?.meta_data?.page_no + 1
      }
      return undefined
    },
  })

  const appOptions = response?.data?.pages?.flatMap(page => page.data) ?? []
  const metaData = response?.data?.pages[0]?.meta_data
  return {
    ...response,
    appOptions,
    metaData,
    isLoadingOptions: response.isLoading,
    isFetchingOptions: response.isFetching,
    isOptionsPending: response.isPending,
    isOptionsError: response.isError,
    fetchMoreOptions: response.fetchNextPage,
    hasMoreOptions: response.hasNextPage,
  }
}

export const useRetryNodeExecution = () => {
  return useMutate(svc.retryNode, workflowKeys.all, WORKFLOWS_TOASTS.retryNodeExecution)
}
export const useCancelNodeExecution = () => {
  return useMutate(
    svc.cancelNodeExecution,
    workflowKeys.all,
    WORKFLOWS_TOASTS.cancelTriggerExecution,
  )
}

export const useGetWorkflowTags = () => {
  const res = useQuery({
    queryKey: workflowKeys.tags,
    queryFn: svc.getWorkflowTags,
  })
  return {
    tags: res.data,
    tagsOptions: res.data?.map(tag => ({...tag, label: tag.name, value: tag.id})),
    isPending: res.isPending,
    isError: res.isError,
  }
}

export const useModifyWorkflowTags = () => {
  return useMutate(
    svc.modifyWorkflowTags,
    workflowKeys.tags,
    'Workflow tags modified successfully!',
  )
}

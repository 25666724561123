import {toastInfo, toastSuccess} from '@hybr1d-tech/charizard'
import {
  type InvalidateQueryFilters,
  MutationFunction,
  QueryKey,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import type {ToastOptions} from 'react-toastify'

export type MutateToastOptions = ToastOptions<{}> & {hideToast?: boolean; isInfoToast?: boolean}

export const useMutate = <TArguments, TResult>(
  mutationFn: MutationFunction<TArguments, TResult>,
  queryKey?: string[] | QueryKey,
  successMsg?: string,
  toastOptions?: MutateToastOptions,
  invalidateOptions?: {refetchType: InvalidateQueryFilters['refetchType']},
  onSuccess?: any,
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn,

    onSuccess(data, variables, context) {
      if (successMsg && !toastOptions?.hideToast) {
        if (toastOptions?.isInfoToast) {
          toastInfo({
            msg: successMsg,
            options: toastOptions,
          })
        } else {
          toastSuccess({
            msg: successMsg,
            options: toastOptions,
          })
        }
      }
      if (queryKey) {
        queryClient.invalidateQueries({queryKey, refetchType: invalidateOptions?.refetchType})
      }
      if (onSuccess) {
        onSuccess()
      }
    },
    onError(err) {
      console.error(err)
    },
  })
}

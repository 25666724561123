import * as React from 'react'
import * as menu from '@zag-js/menu'
import clsx from 'clsx'
import NotificationPreferencesModal from '../../notifications/NotificationPreferencesModal'
import classes from './styles.module.css'
import {useMachine, normalizeProps} from '@zag-js/react'
import {SVG} from '@hybr1d-tech/charizard'
import {useCurrentUser, useLogout} from 'app/store/auth'
import {ICONS} from 'app/utils/constants/icon'
import {handleZenexSwitch, profileMenuItems} from '../ProfileBox'
import {useResetMini, useSidebarMinimized} from 'app/store/layout'
import {useNavigate} from 'react-router'
import {GET_PROFILE} from 'app/routing/getters'
import {USER_STATUS} from 'types/user'

export enum PROFILE_MENU_ITEMS {
  PROFILE = 'profile',
  NOTIFICATION = 'notification',
  SIGN_OUT = 'sign-out',
  ZENEX_SWITCHER = 'zenex-switcher',
}

export default function ProfileDropdown({isOpen, onOpen, onClose}) {
  const logout = useLogout()
  const isMini = useSidebarMinimized()
  const resetMini = useResetMini()

  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const isIncognito = currentUser?.status === USER_STATUS.INCOGNITO

  const handleProfileClick = () => {
    if (!isIncognito) {
      navigate(GET_PROFILE(currentUser?.id))
    }
  }

  const [state, send] = useMachine(
    menu.machine({
      id: React.useId(),
      'aria-label': 'Profile Menu',
      onSelect: ({value}) => {
        switch (value) {
          case PROFILE_MENU_ITEMS.PROFILE:
            resetMini()
            handleProfileClick()
            break
          case PROFILE_MENU_ITEMS.NOTIFICATION:
            resetMini()
            onOpen()
            break
          case PROFILE_MENU_ITEMS.SIGN_OUT:
            logout()
            break
          case PROFILE_MENU_ITEMS.ZENEX_SWITCHER:
            handleZenexSwitch()
            break
        }
      },
      positioning: {placement: 'top-end'},
    }),
  )

  const menuApi = menu.connect(state, send, normalizeProps)

  return (
    <div className={classes.box} onClick={e => e.stopPropagation()} style={{position: 'relative'}}>
      {!isMini && (
        <>
          <button {...menuApi.getTriggerProps()} className={clsx(classes.trigger, 'zap-reset-btn')}>
            <SVG
              path={ICONS.moreMenuVertical}
              svgClassName={classes.logo1}
              spanClassName={classes.logo1Span}
            />
          </button>
          {menuApi.open && (
            <div {...menuApi.getPositionerProps()} className={classes.menus}>
              <ul {...menuApi.getContentProps()}>
                {profileMenuItems.map(item => (
                  <li
                    key={item.id}
                    {...menuApi.getItemProps({value: item.id})}
                    className={classes.menu}
                  >
                    <SVG path={item.icon} svgClassName={classes.logo} />
                    <span className={'zap-subcontent-medium'}>{item.label}</span>
                  </li>
                ))}

                <div
                  key={PROFILE_MENU_ITEMS.ZENEX_SWITCHER}
                  {...menuApi.getItemProps({value: PROFILE_MENU_ITEMS.ZENEX_SWITCHER})}
                  className={classes.menu}
                >
                  <SVG
                    path={ICONS.zenexSwitcher}
                    width={20}
                    height={20}
                    customSpanStyles={{marginRight: '-4px'}}
                  />
                  <span
                    className={clsx(classes.menuLabel, 'zap-subcontent-medium')}
                    style={{marginLeft: '-2px'}}
                  >
                    Go to Zen Ex
                  </span>
                  <SVG
                    path={ICONS.creditCardExternal}
                    width={16}
                    height={16}
                    customSpanStyles={{marginLeft: 'auto'}}
                  />
                </div>

                <div
                  key={PROFILE_MENU_ITEMS.SIGN_OUT}
                  {...menuApi.getItemProps({value: PROFILE_MENU_ITEMS.SIGN_OUT})}
                  className={classes.menu}
                >
                  <SVG path={ICONS.logout} svgClassName={classes.logoRed} />
                  <span className={clsx(classes.menuLabelRed, 'zap-subcontent-medium')}>
                    Sign out
                  </span>
                </div>
              </ul>
            </div>
          )}
        </>
      )}
      {isOpen && <NotificationPreferencesModal isOpen={isOpen} onClose={onClose} />}
    </div>
  )
}

import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'

import classes from './styles.module.css'

export default function MergeNode(props: NodeProps) {
  const {
    data: {nodeContent},
  } = props

  return (
    <div>
      <div className={classes.container}>
        <Handle
          type="target"
          position={Position.Top}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={true}
        />
        <CustomNodeWrapper isSelected={false} style={{padding: '8px 12px'}}>
          <div className={classes.nodeContent}>
            <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
          </div>
        </CustomNodeWrapper>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={false}
        />
      </div>
    </div>
  )
}

import Login from './pages/login'
import Registration from './pages/registration'
import MagicLogin from './pages/magic-login'
import {Route, Routes} from 'react-router'
import EmailVerification from './pages/email-verification'

export default function AuthPage() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="magic-login" element={<MagicLogin />} />
      <Route path="activate/:verificationToken" element={<EmailVerification />} />
      <Route index element={<Login />} />
      <Route path="*" element={<Login />} />
    </Routes>
  )
}

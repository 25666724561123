import {create} from 'zustand'
import {createTableStore, DEFAULT_LIMIT, DEFAULT_PAGE} from '@hybr1d-tech/charizard'
import {QueryState, stringifyRequestQuery} from 'hybrid-ui/helpers'

interface IProductStore {
  productQuery: typeof initialProductQueries
  stringifiedProductQuery: string
  productsDispatch: (action: any) => void
}

export enum PRODUCT_ACTION_TYPES {
  PRODUCT_FILTER = 'product_filter',
  REMOVE_PRODUCT_FILTER = 'remove_product_filter',
  SEARCH = 'search',
  SET_COUNTRY = 'set_country',
  SORT = 'sort',
  VIEW = 'view',
}

const initialProductQueries: QueryState = {
  limit: 24,
  search: '',
  product_filters: {
    brand: [],
    product_type: [],
    processor: [],
    ram: [],
    storage: [],
    operating_system: [],
    country_id: [],
  },
  sort_order: 'asc',
  view: 'grid',
}

export const useProductStore = create<IProductStore>(set => ({
  productQuery: initialProductQueries,
  stringifiedProductQuery: stringifyRequestQuery(initialProductQueries),
  productsDispatch: action => set(state => productQueryDispatcher(state, action)),
}))

const productQueryReducer = (query, {payload, type}) => {
  switch (type) {
    case PRODUCT_ACTION_TYPES.PRODUCT_FILTER: {
      const key = payload.key
      delete payload.key

      let result = query.product_filters[key].concat(payload).filter(function ({id}) {
        // @ts-ignore
        return !this.has(id) && this.add(id)
      }, new Set())

      return {
        ...query,
        product_filters: {
          ...query.product_filters,
          [key]: result,
        },
      }
    }

    case PRODUCT_ACTION_TYPES.REMOVE_PRODUCT_FILTER: {
      const key = payload.key

      return {
        ...query,
        product_filters: {
          ...query.product_filters,
          [key]: query.product_filters[key].filter(item => item.id !== payload.id),
        },
      }
    }

    case PRODUCT_ACTION_TYPES.SEARCH: {
      return {...query, search: payload}
    }

    case PRODUCT_ACTION_TYPES.SET_COUNTRY: {
      return {...query, product_filters: {...query.product_filters, country_id: [payload]}}
    }

    case PRODUCT_ACTION_TYPES.SORT: {
      return {...query, sort_order: payload}
    }

    case PRODUCT_ACTION_TYPES.VIEW: {
      return {...query, view: payload}
    }

    default: {
      return query
    }
  }
}

const productQueryDispatcher = (state: IProductStore, action) => {
  const updatedQuery = productQueryReducer(state.productQuery, action)
  return {
    stringifiedProductQuery: stringifyRequestQuery(updatedQuery),
    productQuery: updatedQuery,
  }
}

// * Quotation List Store
export const quotationInitialQueries = {
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
  search: '',
  sort_by: '',
  sort_order: '',
  filters: {
    filter_requested_by: '',
    filter_request_type: '',
    filter_date_of_request: '',
    filter_date_of_expiry: '',
    filter_status: 'all',
    filter_country: '',
  },
}

export type QuotationQueries = typeof quotationInitialQueries
export const useQuotationStore = createTableStore(quotationInitialQueries)

interface HardwareQuoteState {
  productType: string
  brandName: string
  modelName: string
  screenSize: string
  ram: string
  storage: string
}

interface HardwareQuoteActions {
  setDetails: (details: Partial<HardwareQuoteState>) => void
  reset: () => void
}

interface HardwareQuoteStore extends HardwareQuoteState, HardwareQuoteActions {}

const initialHardwareStore = {
  productType: '',
  brandName: '',
  modelName: '',
  screenSize: '',
  ram: '',
  storage: '',
}

export const useHardwareQuoteStore = create<HardwareQuoteStore>(set => ({
  ...initialHardwareStore,
  setDetails: details =>
    set(state => ({
      ...state,
      ...details,
    })),
  reset: () => set(initialHardwareStore),
}))

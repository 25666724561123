import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Edge, Handle, NodeProps, Position, useStore} from 'reactflow'
import {useCreateWorkflowStore} from 'app/store/workflow'

import classes from './style.module.css'
import {ICONS} from 'app/utils/constants/icon'
import {isConditionalEdge, isGenesisNodeConnectable} from 'app/utils/helper/create-workflow'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'

export default function GenesisNode(props: NodeProps) {
  const state = useStore(state => state)
  const [childEdgeSelected, setChildEdgeSelected] = React.useState(false)
  const {
    data: {nodeContent},
  } = props

  const handleDrawerState = useCreateWorkflowStore(state => state.handleDrawerState)
  const nodes = useCreateWorkflowStore(state => state.nodes)
  const selectedEdge = useCreateWorkflowStore(state => state.selectedEdge)
  const edges = useCreateWorkflowStore(state => state.edges)

  React.useEffect(() => {
    if (nodes.length === 1) {
      state.addSelectedNodes([props.id])
      handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, true, props.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (
      selectedEdge &&
      selectedEdge?.source === props.id &&
      !isConditionalEdge(selectedEdge as unknown as Edge)
    ) {
      setChildEdgeSelected(true)
    } else if (selectedEdge && selectedEdge?.source !== props.id) {
      setChildEdgeSelected(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEdge?.id])

  return (
    <>
      <div
        className={classes.button}
        // onClick={() => handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, true, props.id)}
        onClick={() => handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, true, props.id)}
      >
        <CustomNodeWrapper isSelected={props.selected}>
          {nodeContent ? (
            <div className={classes.nodeContent}>
              <img src={nodeContent?.icon} alt="forward" />
              <div>
                <p className={classes.title}>{nodeContent?.title}</p>
                <p className={classes.subTitle}>{nodeContent?.subTitle}</p>
              </div>
            </div>
          ) : (
            <div className={classes.normalContent}>
              <img src={ICONS.chevronDoubleRight} alt="forward" />
              Trigger
            </div>
          )}
        </CustomNodeWrapper>
        <div className={classes.imageContainer}>
          <img
            src={ICONS.deleteBin}
            alt={'delete'}
            className={classes.deleteIcon}
            title="Delete node"
          />
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: !props.data.showHandle
            ? 'transparent'
            : childEdgeSelected
              ? '#254dda'
              : '#555',
          border: !props.data.showHandle ? '0px solid transparent' : '1px solid white',
        }}
        onConnect={params => {}}
        isConnectable={isGenesisNodeConnectable(nodes, edges, props.id)}
      />
    </>
  )
}

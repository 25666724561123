import ZAPHelmet from 'hybrid-ui/components/zap-helmet'
import AuthWrapper from '../../shared/components/auth-wrapper'
import LoginForm from './components/login-form'

export default function Login() {
  return (
    <>
      <ZAPHelmet title="Login | ZenAdmin" />
      <AuthWrapper>
        <LoginForm />
      </AuthWrapper>
    </>
  )
}

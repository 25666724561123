import {Condition} from 'app/modules/workflow/components/drawer-form/utils/types'

export enum LEAVE_UNIT {
  DAYS = 'days',
  HOUR = 'hours',
}

export enum LEAVE_STATUS {
  APPROVED = 'approved',
  PENDING = 'pending',
  DECLINED = 'declined',
}

export enum APPROVER_LEVEL {
  FIRST_APPROVER = 'first_approver',
  SECOND_APPROVER = 'second_approver',
}

export enum ACTIVITY_TYPE_VALUE {
  NOT_WORKING_PAID = 'not_working_paid',
  NOT_WORKING_UNPAID = 'not_working_unpaid',
  WORKING_PAID = 'working_paid',
}

export interface BalanceActivity {
  label: string
  carryover_expire?: string
  details: {
    label: string
    value?: number
  }[]
}

export interface Approver {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  approver_level: APPROVER_LEVEL
}

export interface LeaveTypeDetails {
  id: string
  label: string
  start_date: Date
  end_date: Date
  approvers: Approver[]
  settings: string[]
  balance_activity: BalanceActivity[]
}

export enum REMAINING_LEAVE {
  UNLIMITED = 'Unlimited',
}

export interface TimeAwayResponse {
  id: string
  name: string
  color: string
  emoji: string
  emoji_label: string
  unit: LEAVE_UNIT
  activity_type: {
    label: string
    value: ACTIVITY_TYPE_VALUE
  }
  total: number | REMAINING_LEAVE
  remaining: number
  details: LeaveTypeDetails
}

export interface LeaveActivityResponse {
  id: string
  leave_type: {
    id: string
    name: string
    color: string
  }
  policy_type: {
    id: string
    name: string
  }
  start_date: Date
  end_date: Date
  status: LEAVE_STATUS
  requested_date: Date
  approval_activities: [
    {
      id: string
      approver_details: {
        first_name: string
        middle_name?: string
        last_name: string
      }
      approver_level: APPROVER_LEVEL
    },
  ]
  supporting_document: string
  note: string
  total_days: number
}

export interface LeaveTypeConfigResponse {
  id: string
  name: string
  half_day: boolean
  probation_period: boolean
  past_dates: boolean
  past_days: number
  future_dates: boolean
  future_days: number
  maximum_leaves: number
  supporting_document: boolean
  minimum_leave_document: number
  note: boolean
  emoji: string
  emoji_label?: string
}

export enum AWAY_TYPE {
  ALL_DAY = 'all',
  FIRST_HALF = 'first',
  SECOND_HALF = 'second',
}

export enum LEAVE_AWAY_TYPE {
  ALL_DAY = 'all',
  FIRST_HALF = 'firsthalf',
  SECOND_HALF = 'secondhalf',
}

export interface LeaveRequestPayload {
  start_date: Date | null
  end_date: Date | null
  note: string
  start_away: LEAVE_AWAY_TYPE
  end_away: LEAVE_AWAY_TYPE
  supporting_document: any
  leave_type_id: string
  isValid?: boolean
  supporting_document_details?: {
    file_name: string
    key: string
    type: string
    ext: string
    size: string
  }
}

export interface LeaveBalanceResponse {
  days: number
  remaining_balance: number
  message: string
}

export interface LeaveBalancePayload {
  start_date: Date | null
  end_date: Date | null
  start_away: LEAVE_AWAY_TYPE
  end_away: LEAVE_AWAY_TYPE
  leave_type_id: string
}

export interface LeaveActivitiesQueryParams {
  start: string
  end: string
}

export interface Calendar {
  id: string
  name: string
  country: string
  country_code: string
  region: string
  region_code: null
  source_public_holiday_calendar_id: null
  type: 'custom' | 'standard'
  day_count: string
  company_id: null
  employeeCount: number
  calendar_type: string
}

export interface Holiday {
  id: string
  name: string
  public_holiday_calendar_id: string
  type: 'custom' | 'standard'
  enabled: boolean
  date: string
  company_id: string
}

export enum PublicHolidayCalendarApplicableTo {
  ALL = 'all',
  CUSTOM = 'custom',
}

export interface PublicHolidayCalendarApplicableToSchema {
  policy_applicable: {
    applicable_to: PublicHolidayCalendarApplicableTo
    custom: Condition[][]
  }
}

export interface HolidayEnabledState {
  id: string
  enabled: boolean
}

export interface UsersPH {
  id: string
  first_name: string
  last_name: string
  work_email: string
  company_id: string
  public_holiday_calendar_id: string
  profile_img_url: string
}

export interface NewHolidayPayload {
  holidays: {
    name: string
    date: string
  }[]
}

export interface EditHolidayPayload {
  name: string
  date: string
}

export interface CustomCalendarPayload {
  name: string
  source_public_holiday_calendar_id: string | null
}

export interface DeleteHolidayResponse {
  success: boolean
  message: string
}

export interface DeleteCalendarResponse {
  success: boolean
  message: string
}

export interface AddCustomCalendarResponse {
  id: string
  name: string
  source_public_holiday_calendar_id: string
}

export interface AddStandardCalendarResponse {
  success: boolean
  message: string
}

export interface AddNewHolidayResponse {
  success: boolean
  message: string
}

export interface EditHolidayResponse {
  success: boolean
  message: string
}

export interface ManageEmployeesResponse {
  success: boolean
  message: string
}

export interface DuplicateCalendarResponse {
  success: boolean
  message: string
}

export interface GetPublicHolidayCalendarsResponse {
  company_id: string
  id: string
  name: string
  region: string
  region_code: string
  type: 'custom' | 'standard'
  source_public_holiday_calendar_id: string
  country: string
  country_code: string
  day_count: number
}
interface SourceCalendarsResponse {
  id: string
  name: string
  country_id: string
  region: string
  region_code: string
  source_public_holiday_calendar_id: string
  type: string
  company_id: string
  created_at: string
  updated_at: string
  deleted_at: string
  country: {
    name: string
    code: string
  }
  holiday_count: string
}
export interface GetAllSourceCalendarsResponse {
  custom?: SourceCalendarsResponse[]
  standard?: SourceCalendarsResponse[]
}

export interface GetEmployeesByPHIdResponse {
  id: string
  first_name: string
  middle_name: string | undefined
  last_name: string
  work_email: string
  email: string
  profile_img_url: string
}

export interface GetHolidaysListResponse {
  year: number
  calendar_name: string
  employee_count: string
  holiday_count: string
  holidays: {
    id: string
    name: string
    date: string
    company_id: string
    public_holiday_calendar_id: string
    enabled: true
    type: 'standard' | 'custom'
    day: string
  }[]
}

export interface GetManageEmployeesConditionsResponse {
  label: string
  value: string
  type: string
  operators: {
    label: string
    value: string
    db_operator: string
  }[]
  values: {
    type: string
    options: {
      label: string
      value: string
    }[]
  }
}

export interface GetManageEmployeesListResponse {
  userCount: number
  users: {
    id: string
    first_name: string
    last_name: string
    work_email: string
    company_id: string
    public_holiday_calendar_id: string
    profile_img_url: string
  }[]
}

import {GetOrgTreeUserResponse, ITeamMemberDetails} from 'types/team'
import {IAssignee} from 'types/tasks'
import {ISoftwareOwner} from 'types/software'
import {Address} from 'app/modules/settings/utils/schema'
import {FullName} from 'types/user'
import {OrderAssignedTo} from 'types/order'

export const getUserPosition = (
  user: ITeamMemberDetails | undefined | GetOrgTreeUserResponse,
  useNA = true,
) => (user?.position?.name || user?.user_position?.position?.name) ?? (useNA ? 'N/A' : '')

export const getUserDepartment = (
  user?: ITeamMemberDetails | GetOrgTreeUserResponse,
  useNA?: boolean,
) => {
  return user?.user_department?.department.name ?? (useNA ? 'N/A' : '')
}

export const getUserDepartmentAlt = (user?: any) => {
  return user?.department?.name ?? 'N/A'
}

export const getUserPositionWithDepartment = (user: ITeamMemberDetails | undefined) => {
  const position = getUserPosition(user) || 'N/A'
  const department = getUserDepartment(user) || 'N/A'
  return `${position} ${user?.department?.id || user?.user_department ? `in ${department}` : ''}`
}

export const getUserPositionWithDepartmentAlt = (user: any) => {
  const position = getUserPosition(user) || 'N/A'
  const department = getUserDepartmentAlt(user)
  return `${position} ${user?.department?.id || user?.user_department ? `in ${department}` : ''}`
}

export const setFilterSearchParam = (
  filter: {filterType: string; id: string; isIdSelected: boolean},
  filterType: string,
  searchParams,
  deleteSearchParamKey,
  updateSearchParams,
  addNewSearchParams,
  replaceValues = false,
) => {
  if (searchParams.get(filterType)) {
    if (replaceValues) {
      updateSearchParams(filterType, filter.id)
      return
    }
    const paramFilter = searchParams.get(filterType)?.split(',')
    if (paramFilter?.find(id => id === filter.id)) {
      const newParamFilter = paramFilter.filter(id => id !== filter.id)
      if (newParamFilter.length === 0) {
        deleteSearchParamKey(filterType)
      } else {
        updateSearchParams(filterType, newParamFilter.join(','))
      }
    } else {
      updateSearchParams(filterType, paramFilter?.concat(filter.id).join(','))
    }
  } else {
    addNewSearchParams(filterType, filter.id)
  }
}

export const getUsername = (
  user?:
    | ITeamMemberDetails
    | IAssignee
    | ISoftwareOwner
    | FullName
    | OrderAssignedTo
    | null
    | undefined,
) => {
  if (!user || !user.first_name) return '-'

  let userName = user.first_name

  if (user.middle_name) {
    userName += ` ${user.middle_name}`
  }

  if (user.last_name) {
    userName += ` ${user.last_name}`
  }

  return userName
}

export const getFormattedAddress = (address: Address) => {
  return [
    address?.address_line1,
    address?.address_line2,
    address?.city,
    address?.state,
    address?.country?.name,
    address?.postal_code,
  ]
    .filter(t => !!t && t !== 'N/A')
    .join(', ')
}

export interface UserWorkPlace {
  city: string | null
  country: {
    id: string
    name: string
    country_code: string
  } | null
}

export const getWorkplace = (workplace: UserWorkPlace | null): string => {
  if (!workplace) return '-'
  const {city, country} = workplace

  if (city && country) {
    return `${city}, ${country.name}`
  } else if (city) {
    return city
  } else if (country) {
    return country.name
  } else {
    return '-'
  }
}

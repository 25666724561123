import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'
import classes from './style.module.css'

export default function MiddleNode(props: NodeProps) {
  const {
    data: {nodeContent, execution_data},
  } = props



  return (
    <div>
      <div
        className={classes.container}
      >
        <Handle
          type="target"
          position={Position.Top}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={false}
        />
        <CustomNodeWrapper isSelected={props.selected} execution_data={execution_data}>
          <div className={classes.nodeContentParent}>
            <div className={classes.nodeContent}>
              <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
              <div>
                <p className={classes.title}>{nodeContent?.title}</p>
                <p className={classes.subTitle}>{nodeContent?.subTitle}</p>
              </div>
            </div>
          </div>
        </CustomNodeWrapper>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={false}
        />
      </div>
    </div>
  )
}

import * as React from 'react'
import classes from './styles.module.css'

export default function ZAPOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = React.useState<boolean>(true)
  const [showMessage, setShowMessage] = React.useState<boolean>(false)

  React.useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false)
      setShowMessage(true)
    })
    window.addEventListener('online', () => {
      setOnlineStatus(true)
      setTimeout(() => {
        setShowMessage(false)
      }, 1000)
    })

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false)
        setShowMessage(true)
      })
      window.removeEventListener('online', () => {
        setOnlineStatus(true)
        setTimeout(() => {
          setShowMessage(false)
        }, 1000)
      })
    }
  }, [])
  return showMessage ? (
    <p className={onlineStatus ? classes.online : classes.offline}>
      You are {onlineStatus ? 'online' : 'offline'}
    </p>
  ) : (
    <></>
  )
}

import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position} from 'reactflow'
import {useWorkflowNodeExecutionViewStore} from 'app/store/workflow'

import classes from './styles.module.css'
import ZAPIcon from 'hybrid-ui/components/zap-icon/ZAPIcon'
import {getNodeStatusTooltipInfo} from '../../utils/helper'
import {TRIGGER_EXECUTION_STATUS} from 'types/workflows'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'
import {TooltipV2} from '@hybr1d-tech/charizard'

export default function MergeNode(props: NodeProps) {
  const {
    data: {nodeContent, execution_data},
  } = props

  const handleDrawerState = useWorkflowNodeExecutionViewStore(state => state.handleDrawerState)

  const handleSelect = (type: DRAWER_CONTENT_TYPE) => {
    handleDrawerState(type, props.id)
  }

  return (
    <div>
      <div
        className={classes.container}
        onClick={() =>
          ![
            TRIGGER_EXECUTION_STATUS.NOT_EXECUTED,
            TRIGGER_EXECUTION_STATUS.NOT_TO_BE_EXECUTED,
            TRIGGER_EXECUTION_STATUS.CANCELLED,
          ].includes(execution_data?.status)
            ? handleSelect(props.data.drawerContent)
            : () => {}
        }
      >
        <Handle
          type="target"
          position={Position.Top}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={false}
        />
        <CustomNodeWrapper
          isSelected={props.selected}
          style={{padding: '8px 12px'}}
          execution_data={execution_data}
        >
          <div className={classes.nodeContent}>
            <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
            <div className={classes.infoImage}>
              <TooltipV2
                portalClass="react-flow__renderer"
                trigger={
                  <ZAPIcon
                    path={getNodeStatusTooltipInfo(execution_data?.status).src}
                    variant={getNodeStatusTooltipInfo(execution_data?.status).variant}
                    size={18}
                  />
                }
                content={
                  execution_data?.message ||
                  getNodeStatusTooltipInfo(execution_data?.status).message
                }
              />
            </div>
          </div>
        </CustomNodeWrapper>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{background: '#555'}}
          onConnect={params => {}}
          isConnectable={false}
        />
      </div>
    </div>
  )
}

import * as Yup from 'yup'
import {useGoogleLogin} from '@react-oauth/google'
import {ONBOARDING_TYPES} from 'types/user'
import {BROWSER_ID, EVENT_TYPE, isDev, trackEvent} from 'app/configs'
import {AuthService, StorageService} from 'app/services'
import {toastInfo, toastSuccess} from '@hybr1d-tech/charizard'

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  // password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
  // .required('Password is required'),
})

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const registrationSchema = Yup.object().shape({
  first_name: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  middle_name: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .optional(),
  last_name: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  phone_number: Yup.string(),
  work_email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
    .test('is-valid-domain', `Please provide correct company email`, function (value, testContext) {
      // todo add all the temp mail providers
      // https://github.com/disposable-email-domains/disposable-email-domains/blob/master/disposable_email_blocklist.conf
      const disabledDomains = new Set([
        'gmail.com',
        'outlook.com',
        'proton.me',
        'givmail.com',
        'votomo.com',
        'dropjar.com',
        'getnada.com',
        'inboxbear.com',
        'ema-sofia.eu',
        'yahoo-inc.com',
        'yahoo.com',
        'zoho.com',
        'yahoo.co.uk',
        'hotmail.com',
      ])

      const domain = value ? value.substring(value.lastIndexOf('@') + 1, value.length) : ''
      let valid = true
      if (disabledDomains.has(domain)) valid = false
      return (
        valid ||
        testContext.createError({
          path: testContext.path,
          message: 'Please use valid company email',
        })
      )
    }),
  // country_id: Yup.string().required('Country is required'),
  company_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: schema =>
        schema.oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

//* handlers
export const handleMagicLinkAuth =
  ({setLoading, hasAcceptedTerms}) =>
  async values => {
    if (!hasAcceptedTerms) return
    setLoading({status: true, source: 'normal'})
    try {
      const res = await AuthService.sendMagicLink(values.email?.toLowerCase())
      if (!res.success) {
        if (StorageService.has('auth_key')) {
          window.location.href = window.location.origin
        } else if (isDev) {
          if (!StorageService.has('auth_retry_count')) {
            StorageService.set('auth_retry_count', '0')
          } else {
            const authRetryCount = StorageService.get('auth_retry_count')
            if (+authRetryCount > 0) {
              toastInfo({msg: 'Clearing browser data to login again'})
              StorageService.remove(BROWSER_ID)
              StorageService.remove('auth_retry_count')
            } else {
              StorageService.set('auth_retry_count', authRetryCount + 1)
            }
          }
        }
      } else {
        toastSuccess({msg: res?.message})
      }
      // todo track magic login link
    } catch (err: any) {
      console.error(err?.response?.data?.message)
    } finally {
      setLoading({status: false, source: 'normal'})
    }
  }

export const handleValidateMagicLink = async (refKey, saveAuth, setCurrentUser) => {
  try {
    const auth = await AuthService.validateMagicLink(refKey)
    saveAuth(auth)

    if (isDev) {
      StorageService.remove('auth_retry_count')
    }

    const user = await AuthService.getUserByToken()
    trackEvent(EVENT_TYPE.LOGIN, {
      organization: user?.company?.name,
      login_method: 'magic link',
      login_success: true,
    })
    setCurrentUser(user)

    return true
  } catch (err) {
    console.error(err)
    saveAuth(undefined)
    throw new Error('Invalid login link')
  }
}

export const useGoogleLoginWithMagicLink = ({setLoading, saveAuth, setCurrentUser}) => {
  const login = useGoogleLogin({
    // ux_mode: 'redirect',
    onSuccess: async ({code}) => {
      try {
        setLoading({status: true, source: 'google'})
        const auth = await AuthService.googleMagicLogin(code)
        saveAuth(auth)
        const user = await AuthService.getUserByToken()
        setCurrentUser(user)
      } catch (err: any) {
        console.error(err?.response?.data?.message)
      } finally {
        setLoading({status: false, source: 'google'})
      }
    },
    onError: err => {
      setLoading({status: false, source: 'google'})
    },
    flow: 'auth-code',
  })

  return {login}
}

export const handleMicrosoftMagicLogin = async ({setLoading, params, saveAuth, setCurrentUser}) => {
  setLoading({status: true, source: 'microsoft'})
  try {
    const auth = await AuthService.microsoftMagicLogin(params)
    saveAuth(auth)
    const user = await AuthService.getUserByToken()
    setCurrentUser(user)
  } catch (err: any) {
    console.error(err?.response?.data?.message)
  } finally {
    setLoading({status: false, source: 'microsoft'})
  }
}

export const handleRegister =
  ({setLoading, saveAuth, setCurrentUser}) =>
  async (values, {setStatus, setSubmitting, setFieldValue}) => {
    setLoading({status: true, source: 'normal'})
    setStatus(null)
    try {
      const payload = {
        ...values,
        work_email: values.work_email?.toLowerCase(),
        onboarding_type: ONBOARDING_TYPES.SELF_ONBOARDING,
      }

      const {data} = await AuthService.register(payload)

      if (data.message === 'Success') {
        // const auth = await login(values.work_email, values.password)
        await handleMagicLinkAuth({
          setLoading,
          hasAcceptedTerms: true,
        })({email: values.work_email})
      }
      // const user = await AuthService.getUserByToken()
      // setCurrentUser(user)
    } catch (error: any) {
      console.error(error)
      // hacky approach due to API and UI mismatch
      setFieldValue('changepassword', values.password)
      saveAuth(undefined)
      let status = 'The registration details are incorrect'
      const errMsg = error?.response?.data?.message.toLowerCase()
      if (errMsg === 'company already exists') {
        status = 'Company already exists, check with your IT admin'
      }
      if (errMsg === 'user already exists') {
        status = 'User already exists, please login'
      }
      setStatus(status)
      setSubmitting(false)
      setLoading({status: false, source: 'normal'})
    }
  }

export const handleEmailVerificationToken = async ({verificationToken, setIsTokenValid}) => {
  try {
    await AuthService.requestEmailVerificationToken({email_confirmation_token: verificationToken})
    setIsTokenValid(true)
  } catch (error) {
    setIsTokenValid(false)
  }
}

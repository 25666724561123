import {CHROME_EXTENSION_ID, isProduction} from 'app/configs'

export const chromeExtensionHelper = (user: any) => {
  if (typeof chrome !== 'undefined' && isProduction && CHROME_EXTENSION_ID) {
    chrome?.runtime?.sendMessage(CHROME_EXTENSION_ID, {user}, response => {
      if (!response.success) {
        console.log('error sending message', response)
        return response
      }
      console.log('Success ::: ', response.message)
    })
  }
}

import {
  BUTTON_VARIANT,
  Button,
  Checkbox,
  InputControlV2,
  InputGroup,
  LabelV2,
  InputV2,
  InputGroupV2,
} from '@hybr1d-tech/charizard'
import {UtilityService} from 'app/services/utility/utility.service'
import {useSaveAuth, useSetCurrentUser} from 'app/store/auth'
import {useUtilStore} from 'app/store/util'
import {useFormik} from 'formik'
import * as React from 'react'
import {Link} from 'react-router'
import {handleRegister, registrationSchema} from '../../../../utils'
import {initialRegistrationValues} from '../../../../utils/constants'
import classes from './styles.module.css'

export default function RegistrationForm() {
  const [loading, setLoading] = React.useState({
    source: 'normal',
    status: false,
  })
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(true)

  const saveAuth = useSaveAuth()
  const setCurrentUser = useSetCurrentUser()

  const formik = useFormik({
    initialValues: initialRegistrationValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setFieldValue}) => {
      if (!hasAcceptedTerms) return
      handleRegister({
        setLoading,
        saveAuth,
        setCurrentUser,
      })(values, {setStatus, setSubmitting, setFieldValue})
    },
  })
  const countries = useUtilStore(s => s.countries)
  const setCountries = useUtilStore(s => s.setCountries)

  React.useLayoutEffect(() => {
    if (!countries.length) UtilityService.getCountries().then(res => setCountries(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form
      className={classes.form}
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className={classes.formHeader}>
        <h1 className={classes.heading}>Create account</h1>

        <div className={classes.subHeading}>
          Already have an account?
          <Link to="/auth/login" className={classes.link}>
            Sign In
          </Link>
        </div>
      </div>

      <InputControlV2>
        <LabelV2 required>First name</LabelV2>

        <InputV2
          {...formik.getFieldProps('first_name')}
          errorMsg={formik.touched.first_name && formik.errors.first_name}
          placeholder="John"
          type="text"
          id="first_name"
        />
      </InputControlV2>
      <InputControlV2>
        <LabelV2>Middle name</LabelV2>

        <InputV2
          {...formik.getFieldProps('middle_name')}
          errorMsg={formik.touched.middle_name && formik.errors.middle_name}
          placeholder=""
          type="text"
          id="middle_name"
        />
      </InputControlV2>
      <InputControlV2>
        <LabelV2 required>Last name</LabelV2>

        <InputV2
          {...formik.getFieldProps('last_name')}
          errorMsg={formik.touched.last_name && formik.errors.last_name}
          placeholder="Doe"
          type="text"
          id="last_name"
        />
      </InputControlV2>
      <InputControlV2>
        <LabelV2 required>Email ID</LabelV2>

        <InputV2
          {...formik.getFieldProps('work_email')}
          errorMsg={formik.touched.work_email && formik.errors.work_email}
          placeholder="Email"
          type="email"
          id="work_email"
        />
      </InputControlV2>

      <InputControlV2>
        <LabelV2 required>Company name</LabelV2>

        <InputV2
          {...formik.getFieldProps('company_name')}
          errorMsg={formik.touched.company_name && formik.errors.company_name}
          placeholder="Company"
          type="text"
          id="company_name"
        />
      </InputControlV2>

      <InputControlV2>
        <LabelV2 required>Password</LabelV2>

        <InputV2
          {...formik.getFieldProps('password')}
          errorMsg={formik.touched.password && formik.errors.password}
          placeholder="Password"
          type="password"
          id="password"
        />

        <div className={classes.secondaryText}>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </InputControlV2>

      <InputControlV2>
        <LabelV2 required>Confirm password</LabelV2>

        <InputV2
          {...formik.getFieldProps('changepassword')}
          errorMsg={formik.touched.changepassword && formik.errors.changepassword}
          placeholder="Password"
          type="password"
          id="changepassword"
        />
      </InputControlV2>

      <div className={classes.toc}>
        <div className={classes.tocInput}>
          <Checkbox
            checked={hasAcceptedTerms}
            onChange={e => setHasAcceptedTerms(e.target.checked)}
            id={React.useId()}
            name="hide_work_email"
          >
            I Agree to the{' '}
            <a
              href="https://zenadmin.ai/terms-of-service"
              target="_blank"
              rel="noreferrer"
              className={classes.termsConditions}
            >
              Terms and Conditions
            </a>
            .
          </Checkbox>
        </div>
        {!hasAcceptedTerms && (
          <div className={classes.errorMsg}>You must accept the terms and conditions</div>
        )}
      </div>

      <Button
        // type="submit"
        variant={BUTTON_VARIANT.PRIMARY}
        customStyles={{width: '100%'}}
        // size="adapt"
        disabled={loading.status}
      >
        <span className={classes.submitBtn}>
          {loading.status ? <>Please wait...</> : 'Launch ZenAdmin'}
        </span>
      </Button>
    </form>
  )
}

import {useSearchParams} from 'react-router'

export default function useAddSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams()

  const addNewSearchParams = (key, value, replace = true) => {
    const params = {}
    searchParams.forEach((key, value) => {
      params[value] = key
    })
    setSearchParams({...params, [key]: value}, {replace: replace})
  }

  const addMultipleSearchParams = (params, replace = true) => {
    const prevParams = {}
    searchParams.forEach((key, value) => {
      prevParams[value] = key
    })
    setSearchParams({...prevParams, ...params}, {replace})
  }

  const removeAllAddNewSearchParams = (key, value) => {
    setSearchParams({[key]: value})
  }

  const clearSearchParams = (config = {}) => {
    setSearchParams({}, config)
  }

  const updateSearchParams = (key, value, replace = false) => {
    searchParams.set(key, value)
    setSearchParams(searchParams, {replace})
  }

  const deleteSearchParamKey = key => {
    searchParams.delete(key)
    setSearchParams(searchParams)
  }

  return {
    searchParams,
    addNewSearchParams,
    removeAllAddNewSearchParams,
    clearSearchParams,
    addMultipleSearchParams,
    updateSearchParams,
    deleteSearchParamKey,
  }
}

import * as React from 'react'
import classes from './styles.module.css'
import {useFormik} from 'formik'
import {
  InputControlV2,
  Button,
  BUTTON_VARIANT,
  LabelV2,
  Checkbox,
  InputV2,
} from '@hybr1d-tech/charizard'
import {handleMagicLinkAuth, loginSchema, useGoogleLoginWithMagicLink} from '../../../../utils'
import {initialLoginValues, authLinks} from '../../../../utils/constants'
import {useSaveAuth, useSetCurrentUser} from 'app/store/auth'
import {MS_REDIRECT_URL, VITE_MICROSOFT_APP_ID, trackEvent} from 'app/configs'
import {IResolveParams, LoginSocialMicrosoft} from './ms-login/MSLogin'
import {AuthService, StorageService, generateKey} from 'app/services'
import {Link} from 'react-router'
import {SEGMENT_SIGN_UP_FUNNEL_STEPS} from 'types/analytics'
import {ICONS} from 'app/utils/constants/icon'

export interface LoadingType {
  source: 'google' | 'normal' | 'microsoft'
  status: boolean
}

export default function LoginForm() {
  const [loading, setLoading] = React.useState<LoadingType>({
    source: 'normal',
    status: false,
  })
  const [hasAcceptedTerms, setHasAcceptedTerms] = React.useState(true)
  const saveAuth = useSaveAuth()
  const setCurrentUser = useSetCurrentUser()

  const emailRef = React.useRef<HTMLInputElement>(null)

  const formik = useFormik({
    initialValues: initialLoginValues,
    validationSchema: loginSchema,
    onSubmit: handleMagicLinkAuth({
      setLoading,
      hasAcceptedTerms,
    }),
  })

  const focusEmail = () => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }

  const handleStorageChange = async (event: StorageEvent) => {
    if (event.key === generateKey('auth_key') && event.newValue) {
      const authKey = StorageService.getAuthKey() || event.newValue
      saveAuth({auth_key: authKey})
      const user = await AuthService.getUserByToken()
      setCurrentUser(user)
    }
  }

  React.useEffect(() => {
    window.addEventListener('focus', focusEmail)
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
      window.removeEventListener('focus', focusEmail)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {login} = useGoogleLoginWithMagicLink({setLoading, saveAuth, setCurrentUser})

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit} noValidate>
        <div className={classes.formHeader}>
          <h1 className={classes.heading}>Sign In</h1>
          <div className={classes.subHeading}>
            {authLinks.bookDemo.loginPageText}{' '}
            <Link
              to="/auth/registration"
              onClick={() => trackEvent(SEGMENT_SIGN_UP_FUNNEL_STEPS.STARTED, {})}
              className={classes.link}
            >
              Create an account
            </Link>
            <a
              href={authLinks.bookDemo.link}
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              {authLinks.bookDemo.name}
            </a>
          </div>
        </div>

        <InputControlV2>
          <LabelV2 required>Email ID</LabelV2>

          <InputV2
            {...formik.getFieldProps('email')}
            errorMsg={formik.touched.email && (formik.errors.email as any)}
            placeholder="Email"
            type="email"
            id="email"
            ref={emailRef}
            disabled={!hasAcceptedTerms}
          />
        </InputControlV2>

        <div className={classes.toc}>
          <div className={classes.tocInput}>
            <Checkbox
              checked={hasAcceptedTerms}
              onChange={e => {
                formik.setFieldTouched('email', false)
                setHasAcceptedTerms(e.target.checked)
              }}
              id={React.useId()}
              name="hide_work_email"
            >
              By clicking continue, you agree to our{` `}
              <a
                href={authLinks.pp.link}
                target="_blank"
                rel="noreferrer"
                className={classes.termsConditions}
              >
                Privacy policy{` `}
              </a>
              and{` `}
              <a
                href={authLinks.tos.link}
                target="_blank"
                rel="noreferrer"
                className={classes.termsConditions}
              >
                {authLinks.tos.name}
              </a>
              .
            </Checkbox>
          </div>

          {!hasAcceptedTerms && (
            <div className={classes.errorMsg}>You must agree to our policy and agreement</div>
          )}
        </div>

        <Button
          // type="submit"
          variant={BUTTON_VARIANT.PRIMARY}
          // size="adapt"
          disabled={!hasAcceptedTerms || formik.isSubmitting || !formik.isValid}
          customStyles={{width: '100%'}}
        >
          <span className={classes.submitBtn}>
            {loading.status && loading.source === 'normal' ? <>Sending Magic Link</> : 'Continue'}
          </span>
        </Button>
      </form>

      <div className={classes.or}>
        <div className={classes.strip}></div>
        or
        <div className={classes.strip}></div>
      </div>

      <button
        onClick={() => {
          formik.setFieldTouched('email', false)
          login()
        }}
        type={'button'}
        className={classes.googleBtn}
        disabled={!hasAcceptedTerms || (loading.status && loading.source === 'google')}
      >
        <img alt="Logo" src={ICONS.google} />
        {loading.status && loading.source === 'google'
          ? 'Logging in with Google...'
          : 'Sign In with Google'}
      </button>

      <LoginSocialMicrosoft
        client_id={VITE_MICROSOFT_APP_ID}
        redirect_uri={MS_REDIRECT_URL || ''}
        isOnlyGetCode={false}
        isOnlyGetToken={false}
        setLoading={setLoading}
        saveAuth={saveAuth}
        setCurrentUser={setCurrentUser}
        onResolve={({provider, data}: IResolveParams) => {
          setLoading({status: false, source: 'normal'})
        }}
        onReject={(err: any) => {
          console.error(err)
        }}
      >
        <button
          type={'button'}
          className={classes.msBtn}
          disabled={!hasAcceptedTerms || (loading.status && loading.source === 'microsoft')}
          onClick={() => {
            formik.setFieldTouched('email', false)
          }}
        >
          <img alt="Logo" src={ICONS.microsoft} />
          {loading.status && loading.source === 'microsoft'
            ? 'Logging in with Microsoft...'
            : 'Sign In with Microsoft'}
        </button>
      </LoginSocialMicrosoft>
    </div>
  )
}

import {apiAxios} from 'app/configs/axios'
import {Condition} from 'app/modules/workflow/components/drawer-form/utils/types'
import { PublicHolidayCalendarQueries, SourceCalendarQueries, PublicHolidayQueries } from 'app/store/time-away'
import {routeFactory} from 'app/utils/helper'
import type {
  AddCustomCalendarResponse,
  AddNewHolidayResponse,
  AddStandardCalendarResponse,
  CustomCalendarPayload,
  DeleteCalendarResponse,
  DeleteHolidayResponse,
  DuplicateCalendarResponse,
  EditHolidayPayload,
  EditHolidayResponse,
  GetAllSourceCalendarsResponse,
  GetEmployeesByPHIdResponse,
  GetHolidaysListResponse,
  GetManageEmployeesConditionsResponse,
  GetManageEmployeesListResponse,
  GetPublicHolidayCalendarsResponse,
  LeaveActivitiesQueryParams,
  LeaveActivityResponse,
  LeaveBalancePayload,
  LeaveBalanceResponse,
  LeaveRequestPayload,
  LeaveTypeConfigResponse,
  ManageEmployeesResponse,
  NewHolidayPayload,
  TimeAwayResponse,
} from 'types/time-away'
import {PaginatedAPIResponse} from 'types/utils'
// import {TableFilters} from 'types/utils'

const timeAwayRouteFactory = routeFactory('/users/v2/time-away')
const leaveRouteFactory = routeFactory('/v2/leave')
const publicHolidayFactory = routeFactory('/v2/public-holidays')

export class TimeAwayServiceV2 {
  async getTimeAwayDetails(userId: string): Promise<TimeAwayResponse[]> {
    const res = await apiAxios.get(timeAwayRouteFactory(userId))
    return res.data?.data
  }

  async getLeaveActivities(
    userId: string,
    leaveActivityPeriod: LeaveActivitiesQueryParams,
  ): Promise<LeaveActivityResponse[]> {
    const params = leaveActivityPeriod
    const res = await apiAxios.get(timeAwayRouteFactory(`activities/${userId}`), {
      params,
    })
    return res.data?.data
  }

  async getLeaveConfig(userId: string): Promise<LeaveTypeConfigResponse[]> {
    const res = await apiAxios.get(leaveRouteFactory(`leave-types/config/${userId}`))
    return res.data?.policyDetails
  }

  async createLeaveRequests({userId, payload}: {userId: string; payload: LeaveRequestPayload}) {
    const res = await apiAxios.post(leaveRouteFactory(`book-time-away/${userId}`), payload)
    return res.data?.data
  }

  async getLeaveBalance({
    userId,
    payload,
  }: {
    userId: string
    payload: LeaveBalancePayload
  }): Promise<LeaveBalanceResponse> {
    const res = await apiAxios.post(leaveRouteFactory(`book-time-away/balance/${userId}`), payload)
    return res.data?.periods
  }

  async getPublicHolidayCalendars(
    query: PublicHolidayCalendarQueries,
  ): Promise<PaginatedAPIResponse<GetPublicHolidayCalendarsResponse[]>> {
    const res = await apiAxios.get(publicHolidayFactory('company'), {
      params: query,
    })
    return res?.data
  }

  async getAllCalendars(
    query?: SourceCalendarQueries,
  ): Promise<PaginatedAPIResponse<GetAllSourceCalendarsResponse>> {
    const res = await apiAxios.get(publicHolidayFactory('calendars'), {
      params: query,
    })
    return res?.data
  }

  async getEmployeesByPHId(
    id: string,
  ): Promise<PaginatedAPIResponse<GetEmployeesByPHIdResponse[]>> {
    const res = await apiAxios.get(publicHolidayFactory(`users/${id}`))
    return res?.data
  }

  async addCustomCalendar(payload: CustomCalendarPayload): Promise<AddCustomCalendarResponse> {
    const res = await apiAxios.post(publicHolidayFactory('calendars/custom'), payload)
    return res?.data
  }

  async addStandardCalendar(payload: {
    public_holiday_calendar_id: string
  }): Promise<AddStandardCalendarResponse> {
    const res = await apiAxios.post(publicHolidayFactory('calendars/standard'), payload)
    return res?.data
  }

  async deleteCalendar({calendarId}: {calendarId: string}): Promise<DeleteCalendarResponse> {
    const res = await apiAxios.delete(publicHolidayFactory(`calendars/${calendarId}`))
    return res?.data
  }

  async deleteHoliday({
    calendarId,
    holidayId,
  }: {
    calendarId: string
    holidayId: string
  }): Promise<DeleteHolidayResponse> {
    const res = await apiAxios.delete(publicHolidayFactory(`${calendarId}/${holidayId}`))
    return res?.data
  }

  async getPublicHolidays(
    publicHolidayCalendarId: string,
    query: PublicHolidayQueries,
  ): Promise<PaginatedAPIResponse<GetHolidaysListResponse>> {
    const res = await apiAxios.get(publicHolidayFactory(`${publicHolidayCalendarId}`), {
      params: query,
    })
    return res?.data
  }

  async addNewHoliday({
    calendar_id,
    payload,
  }: {
    calendar_id: string
    payload: NewHolidayPayload
  }): Promise<AddNewHolidayResponse> {
    try {
      const response = await apiAxios.post(
        publicHolidayFactory(`calendars/${calendar_id}`),
        payload,
      )
      return response.data
    } catch (error) {
      console.error('Error adding holiday:', error)
      throw error
    }
  }

  async editHoliday({
    calendar_id,
    id,
    payload,
  }: {
    calendar_id: string
    id: string
    payload: EditHolidayPayload
  }): Promise<EditHolidayResponse> {
    try {
      const response = await apiAxios.patch(publicHolidayFactory(`${calendar_id}/${id}`), payload)
      return response.data
    } catch (error) {
      console.error('Error editing holiday:', error)
      throw error
    }
  }

  async getManageEmployeeConditionsList(): Promise<
    PaginatedAPIResponse<GetManageEmployeesConditionsResponse[]>
  > {
    const res = await apiAxios.get(publicHolidayFactory('manage-employees/conditions'))
    return res?.data
  }

  async getPHManageEmployeesList(
    conditions: Condition[][] | null | undefined,
  ): Promise<PaginatedAPIResponse<GetManageEmployeesListResponse>> {
    const res = await apiAxios.post(publicHolidayFactory('conditions/employees-list'), {
      conditions,
    })
    return res?.data
  }

  async editCalendarConfig({id, payload}): Promise<ManageEmployeesResponse> {
    try {
      const response = await apiAxios.post(publicHolidayFactory(`manage-employees/${id}`), payload)
      return response.data
    } catch (error) {
      console.error('Error editing calendar config:', error)
      throw error
    }
  }

  async duplicateCalendar(id: string): Promise<DuplicateCalendarResponse> {
    try {
      const response = await apiAxios.post(publicHolidayFactory(`calendars/duplicate/${id}`))
      return response.data
    } catch (error) {
      console.error('Error duplicating calendar:', error)
      throw error
    }
  }
}

import {BUTTON_VARIANT, ModalV2} from '@hybr1d-tech/charizard'
import classes from './style.module.css'

export default function DeleteNodeModal({isOpen, onOk, onClose, bodyTitle = '', children}) {
  const footerButtons = [
    {
      variant: BUTTON_VARIANT.SECONDARY,
      btnText: 'Cancel',
      onClick() {
        onClose()
      },
    },
    {
      variant: BUTTON_VARIANT.PRIMARY,
      btnText: 'Delete',
      onClick() {
        onOk()
      },
    },
  ]

  return (
    <ModalV2
      showBackdrop
      title="Delete Node"
      trigger={children}
      footerButtons={footerButtons}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={''}>
        <div className={classes.bodyTitle}>{bodyTitle}</div>
        <div className={classes.bodySubTitle}>
          Are you sure you want to <b>delete</b> this node?
        </div>
      </div>
    </ModalV2>
  )
}

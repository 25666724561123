import * as React from 'react'
import ZAPHelmet from 'hybrid-ui/components/zap-helmet'
import RegistrationForm from './components/registration-form'
import AuthWrapper from '../../shared/components/auth-wrapper'

export default function Registration() {
  return (
    <>
      <ZAPHelmet title="Register | ZenAdmin" />
      <AuthWrapper isSignUp>
        <RegistrationForm />
      </AuthWrapper>
    </>
  )
}

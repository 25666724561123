import * as React from 'react'
import CustomNodeWrapper from '../custom-node-wrapper'
import {Handle, NodeProps, Position, useStore} from 'reactflow'
import {useWorkflowNodeExecutionViewStore} from 'app/store/workflow'

import classes from './style.module.css'
// import {Tooltip} from '@hybr1d-tech/charizard'
import ZAPIcon from 'hybrid-ui/components/zap-icon/ZAPIcon'
import {getNodeStatusTooltipInfo} from '../../utils/helper'
import {DRAWER_CONTENT_TYPE} from 'app/modules/workflow/utils/enum'
import {TooltipV2} from '@hybr1d-tech/charizard'

export default function GenesisNode(props: NodeProps) {
  const state = useStore(state => state)
  const {
    data: {nodeContent, execution_data},
  } = props

  const nodes = useWorkflowNodeExecutionViewStore(state => state.nodes)
  const handleDrawerState = useWorkflowNodeExecutionViewStore(state => state.handleDrawerState)

  React.useEffect(() => {
    if (nodes.length === 1) {
      state.addSelectedNodes([props.id])
      handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, props.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div
        className={classes.button}
        // onClick={() => handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, true, props.id)}
        onClick={() => handleDrawerState(DRAWER_CONTENT_TYPE.GENESIS_NODE, props.id)}
      >
        <CustomNodeWrapper isSelected={props.selected} execution_data={execution_data}>
          <div className={classes.nodeContentParent}>
            <div className={classes.nodeContent}>
              <img className={classes.nodeIcon} src={nodeContent?.icon} alt="forward" />
              <div>
                <p className={classes.title}>{nodeContent?.title}</p>
                <p className={classes.subTitle}>{nodeContent?.subTitle}</p>
              </div>
            </div>
            <div className={classes.infoImage}>
              <TooltipV2
                portalClass="react-flow__renderer"
                trigger={
                  <ZAPIcon
                    path={getNodeStatusTooltipInfo(execution_data?.status).src}
                    variant={getNodeStatusTooltipInfo(execution_data?.status).variant}
                    size={18}
                  />
                }
                content={
                  execution_data?.message ||
                  getNodeStatusTooltipInfo(execution_data?.status).message
                }
              />
              {/* <Tooltip key={props.id}>
                <Tooltip.Trigger>
                  <ZAPIcon
                    path={getNodeStatusTooltipInfo(execution_data?.status).src}
                    variant={getNodeStatusTooltipInfo(execution_data?.status).variant}
                    size={18}
                  />
                </Tooltip.Trigger>
                <Tooltip.Content>
                  {execution_data?.message ||
                    getNodeStatusTooltipInfo(execution_data?.status).message}
                </Tooltip.Content>
              </Tooltip> */}
            </div>
          </div>
        </CustomNodeWrapper>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          background: !props.data.showHandle ? 'transparent' : '#555',
          border: !props.data.showHandle ? '0px solid transparent' : '1px solid white',
        }}
        onConnect={params => {}}
        isConnectable={false}
      />
    </>
  )
}

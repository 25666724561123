import * as React from 'react'
import {Link, useParams} from 'react-router'
import AuthWrapper from '../../shared/components/auth-wrapper'
import {handleEmailVerificationToken} from '../../utils'
import classes from './styles.module.css'

export default function EmailVerification() {
  const [isTokenValid, setIsTokenValid] = React.useState<boolean | undefined>(true)
  const {verificationToken} = useParams()

  React.useEffect(() => {
    handleEmailVerificationToken({verificationToken, setIsTokenValid})
  }, [verificationToken])

  const renderContent = () => {
    if (isTokenValid === false) {
      return (
        <div className={classes.invalid}>
          <h1 className={classes.title}>Invalid Token</h1>
          <div className={classes.description}>
            Your email verification token is either expired or invalid.
          </div>
        </div>
      )
    }

    if (isTokenValid === true) {
      return (
        <div className={classes.valid}>
          <h1 className={classes.title}>Email Verified</h1>
          <div className={classes.description}>Your email has been verified!</div>
          <p className={classes.loginMsg}>
            Please{' '}
            <Link to="/auth/login" className={classes.link}>
              login
            </Link>{' '}
            to continue
          </p>
        </div>
      )
    }

    return null
  }

  return <AuthWrapper hideLeftContainer>{renderContent()}</AuthWrapper>
}

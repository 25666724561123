export const QUERIES = {
  USERS_LIST: 'users-list',
  DEPARTMENT: 'department',
  INVENTORY_LIST: 'inventory-list',
  PRODUCTS_LIST: 'products-list',
  CART: 'cart',
  ADDRESS: 'address',
  USERS_EVENTS: 'users-events',
}

export const PROFILE_QUERIES = {
  PROFILE: 'profile',
  PERSONAL: 'personal',
  PERSONAL_ADDRESS: 'personal-address',
  SOCIAL: 'social',
  HEALTH: 'health',
  BANKING: 'banking',
  ALLERGIES: 'allergies',
}

export const INSIGHTS_QUERIES = {
  COMPANY_GROWTH: 'company-growth',
  HEADCOUNT_DEPT: 'headcount-dept',
  SALARY_DEPT: 'salary-dept',
  TENURE_DEPT: 'tenure-dept',
  LEAVE_MANAGEMENT: 'leave-management',
}

export const INTEGRATION_QUERIES = {
  GOOGLE_WORKSPACE: 'google',
  MICROSOFT_WORKSPACE: 'microsoft',
  SLACK_GENERAL: 'slack-general',
}

export const APP_STORE_QUERIES = {
  ZENADMIN_MODULES: 'zenadmin-modules',
  MODULE_DETAILS: 'module-details',
  GET_MODULE_DETAILS(id) {
    return `module-details-${id}`
  },
  GET_INTEGRATION_DETAILS_QUERY_KEY(app: string) {
    return `${app}-app-store-details`
  },
}

export const SETTINGS_QUERIES = {
  SETTINGS_BASE: 'settings',
  COMPANY: {
    TEAM: 'company-team',
    DEPARTMENT: 'company-department',
    JOB_TITLES: 'company-job-titles',
    LEGAL_ENTITY: 'company-legal-entity',
  },
  CUSTOM_FIELD_CATEGORY_SUBCATEGORY: 'custom-field-category-sub-category',
}
